import { isMobile } from 'mobile-device-detect';

import { EventTypes, GameMode } from '../../global.d';
import { setBrokenBuyFeature, setBrokenGame, setBrokenPickem, setGameMode, setIsTurboSpin } from '../../gql';
import { calcPercentage, isPickemMode } from '../../utils';
import { eventManager } from '../config';

import Button from '.';

class TurboSpinBtn extends Button {
  private gameMode: GameMode;

  constructor() {
    super('turbospin', 40);
    this.updateIntent(setIsTurboSpin() ? 'turbospin' : 'turbospin-off');
    this.interactive = !this.isDisabled;
    if (setBrokenBuyFeature()) {
      this.gameMode = GameMode.BUY_FEATURE;
    } else if (setBrokenGame()) {
      this.gameMode = setGameMode();
    } else if (setBrokenPickem()) {
      this.gameMode = GameMode.PICKEM;
    } else {
      this.gameMode = GameMode.REGULAR;
    }
    this.on('click', () => this.handleClick());
    this.on('touchstart', () => this.handleClick());
    this.visible = !isMobile;
    this.initSubscriptions();
    this.handleDisable();
  }

  private initSubscriptions(): void {
    eventManager.on(EventTypes.TOGGLE_TURBO_SPIN, (isTurboSpin: boolean) => {
      this.updateIntent(isTurboSpin ? 'turbospin' : 'turbospin-off');
      this.handleDisable();
    });
    eventManager.on(EventTypes.CHANGE_MODE, (data: { mode: GameMode }) => {
      this.gameMode = data.mode;
      this.handleDisable();
    });
  }

  private handleDisable(): void {
    this.setDisable(isPickemMode(this.gameMode));
  }

  private handleClick(): void {
    if (!this.isDisabled) {
      setIsTurboSpin(!setIsTurboSpin());
      this.updateIntent(setIsTurboSpin() ? 'turbospin' : 'turbospin-off');
    }
  }

  public handlePosition(): void {
    const x = calcPercentage(this.applicationSize.width, 6.3);
    const y = calcPercentage(this.applicationSize.height, 95.7);
    this.setSize(calcPercentage(this.applicationSize.width, 2.7));

    this.x = x;
    this.y = y;
  }
}

export default TurboSpinBtn;
