import { throwServerError } from '@apollo/client';
import { ITrackEntry } from 'pixi-spine';
import * as PIXI from 'pixi.js';

import SlotMachine from '..';
import { MAPPED_BLURRED_SYMBOLS, MAPPED_SYMBOLS, SlotId } from '../../config';
import { EventTypes } from '../../global.d';
import { ResourceTypes } from '../../resources.d';
import SpineAnimation from '../animations/spine';
import { SLOTS_PER_REEL_AMOUNT, SLOT_HEIGHT, SLOT_WIDTH, WILD_SCALE, eventManager } from '../config';
import { Icon } from '../d';

class Slot extends PIXI.Sprite {
  private animation: SpineAnimation | null = null;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private timeoutId: any;

  private id: number;

  public slotId: SlotId;

  public textureSlotId: SlotId;

  private isPlayedAnimation: boolean;

  private startSpinAnimationListener = () => {
    if (this.isPlayedAnimation) {
      this.timeoutId = setTimeout(() => {
        if (this.animation) {
          this.animation = null;
          this.removeChildren();
        }
      }, 200);
      this.isPlayedAnimation = false;
    }
  };

  private skipSlotsWinAnimation = () => {
    if (this.animation && this.animation?.spine.state.tracks[0]) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (this.animation?.spine.state.tracks[0] as any).animationStart = (
        this.animation?.spine.state.tracks[0] as ITrackEntry
      ).animationEnd;
      this.isPlayedAnimation = true;
    }
  };

  constructor(id: number, slotId: SlotId) {
    super(PIXI.Texture.from(MAPPED_SYMBOLS[slotId]));
    this.id = id;
    this.slotId = slotId;
    this.textureSlotId = slotId;
    if (this.slotId === SlotId.WL) {
      this.width = Math.round(SLOT_WIDTH * WILD_SCALE);
      this.height = Math.round(SLOT_HEIGHT * WILD_SCALE);
      this.anchor.set(0.5, 0);
      this.y = (SLOTS_PER_REEL_AMOUNT - id - 0.5) * SLOT_WIDTH;
      this.x = SLOT_WIDTH / 2;
    } else {
      this.width = SLOT_WIDTH;
      this.height = SLOT_HEIGHT;
      this.y = SLOT_HEIGHT * id;
    }

    this.isPlayedAnimation = false;
    eventManager.addListener(EventTypes.SKIP_WIN_SLOTS_ANIMATION, this.skipSlotsWinAnimation);
  }

  private initAnimation(): void {
    if (this.slotId === SlotId.WL || this.slotId === SlotId.SC1) {
      this.animation = new SpineAnimation({}, PIXI.Loader.shared.resources.symbols.spineData!);
      if (this.slotId === SlotId.WL) {
        this.animation.spine.x = 0;
        this.animation.spine.y = SLOT_WIDTH / 2;
      } else {
        this.animation.spine.x = this.width / 2;
        this.animation.spine.y = this.height / 2;
      }
      this.animation.addOnStart(() => {
        this.addChild(this.animation!.spine);
      });
    }
    if (this.slotId === SlotId.WL) {
      eventManager.addListener(EventTypes.START_SPIN_ANIMATION, this.startSpinAnimationListener);
    }
  }

  private startAnimation(anim: string): void {
    this.animation!.setAnimation(anim, false);
    this.animation!.start();
    this.animation!.complete = [];
    this.animation!.addOnSkip(() => {
      if (this.slotId === SlotId.WL) {
        this.isPlayedAnimation = true;
      }
    });
    this.animation!.addOnComplete(() => {
      if (this.slotId === SlotId.WL) {
        this.isPlayedAnimation = true;
      }
    });
  }

  public changeTexture(slotId: SlotId): void {
    this.texture = PIXI.Texture.from(MAPPED_SYMBOLS[slotId]);
    this.textureSlotId = slotId;
  }

  public toggleBlur(blur: boolean): void {
    if (blur) {
      this.texture = PIXI.Texture.from(MAPPED_BLURRED_SYMBOLS[this.textureSlotId]);
    } else {
      this.texture = PIXI.Texture.from(MAPPED_SYMBOLS[this.textureSlotId]);
    }
  }

  public onSlotStopped(slotIndex: number): void {
    if (this.slotId === SlotId.WL || this.slotId === SlotId.SC1) {
      this.initAnimation();
    }

    if (this.slotId === SlotId.WL) {
      const { nextResult } = SlotMachine.getInstance();
      if (nextResult?.bet.data.features.multipliers) {
        const multipliers = nextResult?.bet.data.features.multipliers;
        if (multipliers[slotIndex]) {
          if (this.timeoutId) {
            clearTimeout(this.timeoutId);
          }
          this.animation!.spine.visible = true;
          this.startAnimation(`wild_land_x${multipliers[slotIndex]}`);
        }
      }
    }
    if (this.slotId === SlotId.SC1) {
      this.startAnimation('scatter_land');
    }
  }

  public onDestroy() {
    this.animation = null;

    eventManager.removeListener(EventTypes.SKIP_WIN_SLOTS_ANIMATION, this.skipSlotsWinAnimation);
    eventManager.removeListener(EventTypes.START_SPIN_ANIMATION, this.startSpinAnimationListener);
  }
}

export default Slot;
