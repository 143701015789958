import * as PIXI from 'pixi.js';

import { ISongs, mappedAudioSprites } from '../../config';
import { EventTypes } from '../../global.d';
import Animation from '../animations/animation';
import { TweenProperties } from '../animations/d';
import { createZoomAnimation } from '../animations/helper';
import SpineAnimation from '../animations/spine';
import Tween from '../animations/tween';
import ViewContainer from '../components/container';
import { WinStages, eventManager } from '../config';

class WinLabelContainer extends ViewContainer {
  private animation: SpineAnimation | null = null;

  constructor() {
    super();
    this.zIndex = 1;
    this.initAnimation();

    eventManager.addListener(EventTypes.SET_BIG_WIN_VISIBILITY, this.setBigWinVisibility.bind(this));
    eventManager.addListener(EventTypes.SET_MEGA_WIN_VISIBILITY, this.setMegaWinVisibility.bind(this));
    eventManager.addListener(EventTypes.SET_GREAT_WIN_VISIBILITY, this.setGreatWinVisibility.bind(this));
    eventManager.addListener(EventTypes.SET_EPIC_WIN_VISIBILITY, this.setEpicWinVisibility.bind(this));
    eventManager.addListener(EventTypes.SKIP_ALL_WIN_ANIMATIONS, this.skipAllWinAnimations.bind(this));
    eventManager.addListener(EventTypes.HANDLE_START_FADE_ANIMATION, (stage: number) => this.startFade(stage));
    eventManager.addListener(EventTypes.HANDLE_SKIP_FADE_ANIMATION, () => this.skipFadeAnimation());
  }

  private initAnimation(): void {
    this.animation = new SpineAnimation({}, PIXI.Loader.shared.resources.win_messages.spineData!);
    this.animation.spine.x = 800;
    this.animation.spine.y = 670;

    this.animation.addOnStart(() => {
      this.addChild(this.animation!.spine);
    });
  }

  private startInAnimation(inAnim: string, loopAnim: string): void {
    this.animation!.setAnimation(inAnim, false);
    this.animation!.start();

    this.animation!.complete = [];
    this.animation!.addOnComplete(() => {
      this.startLoopAnimation(loopAnim, true);
    });
  }

  private startLoopAnimation(anim: string, isLoop = false): void {
    this.animation!.setAnimation(anim, isLoop);
    this.animation!.start();

    this.animation!.complete = [];
  }

  private startOutAnimation(anim: string): void {
    this.animation!.setAnimation(anim, false);
    this.animation!.start();
    this.animation!.complete = [];
  }

  private setBigWinVisibility(visible: boolean) {
    if (visible) {
      this.animation?.skip();
      this.startInAnimation('big_win_in', 'big_win_loop');
    } else {
      this.animation?.skip();
    }
  }

  private setMegaWinVisibility(visible: boolean) {
    if (visible) {
      this.animation?.skip();
      this.startInAnimation('mega_win_in', 'mega_win_loop');
    } else {
      this.animation?.skip();
    }
  }

  private setGreatWinVisibility(visible: boolean) {
    if (visible) {
      this.animation?.skip();
      this.startInAnimation('great_win_in', 'great_win_loop');
    } else {
      this.animation?.skip();
    }
  }

  private setEpicWinVisibility(visible: boolean) {
    if (visible) {
      this.animation?.skip();
      this.startInAnimation('epic_win_in', 'epic_win_loop');
    } else {
      this.animation?.skip();
    }
  }

  private skipAllWinAnimations() {
    this.setBigWinVisibility(false);
    this.setMegaWinVisibility(false);
    this.setGreatWinVisibility(false);
    this.setEpicWinVisibility(false);
  }

  private skipFadeAnimation() {
    this.animation?.skip();
  }

  private startFade(stage: WinStages) {
    if (stage === WinStages.BigWin) {
      this.startOutAnimation('big_win_out');
    }

    if (stage === WinStages.MegaWin) {
      this.startOutAnimation('mega_win_out');
    }

    if (stage === WinStages.GreatWin) {
      this.startOutAnimation('great_win_out');
    }
    if (stage === WinStages.EpicWin) {
      this.startOutAnimation('epic_win_out');
    }
  }
}

export default WinLabelContainer;
