import { debug } from 'console';
import { t } from 'i18next';
import _ from 'lodash';
import MultiStyleText from 'pixi-multistyle-text';
import * as PIXI from 'pixi.js';

import { formatNumber } from '@phoenix7dev/utils-fe';

import { MAPPED_SYMBOLS, SlotId } from '../../config';
import { EventTypes } from '../../global.d';
import { setCoinAmount, setCurrency, setIsMiniPayTable } from '../../gql';
import i18n from '../../i18next';
import { normalizeCoins, showCurrency } from '../../utils';
import {
  PAY_TABLE_BACKGROUND_COLOR,
  PAY_TABLE_HEIGHT,
  PAY_TABLE_WIDTH,
  REELS_AMOUNT,
  REEL_WIDTH,
  SLOT_HEIGHT,
  SLOT_WIDTH,
  WILD_SCALE,
  eventManager,
  miniPayTableMultipliersStyle,
} from '../config';
import { Combos, Icon, IconCombo } from '../d';

class MiniPayTable extends PIXI.Container {
  private id: number;

  private isLast: boolean;

  private currency = '';

  public iconId: SlotId;

  public combos: Combos;

  public rect: PIXI.Graphics;

  public multipliers: MultiStyleText;

  public symbol: PIXI.Sprite;

  constructor(id: number, icon: Icon, combos: Combos) {
    super();
    this.id = id;
    this.isLast = id % REELS_AMOUNT === REELS_AMOUNT - 1;
    // TODO: re-adjustment
    this.x = this.isLast ? -(SLOT_WIDTH * 2 - REEL_WIDTH) : 0;
    this.y = 0;
    this.iconId = icon.id;
    this.width = SLOT_WIDTH * 2;
    this.height = SLOT_HEIGHT;
    this.visible = false;
    this.sortableChildren = true;
    this.combos = _.cloneDeep(combos)?.reverse();
    eventManager.addListener(EventTypes.SHOW_PAY_TABLE, (i: number) => {
      this.showPayTable(i);
    });
    eventManager.addListener(EventTypes.DISABLE_ALL_MINI_PAY_TABLES, () => (this.visible = false));
    eventManager.addListener(EventTypes.START_SPIN_ANIMATION, () => (this.visible = false));
    eventManager.addListener(EventTypes.UPDATE_BET, (betAmount: number) => this.handleChangeBetAmount());
    this.rect = this.initRect();
    this.addChild(this.rect);
    this.symbol = this.initSymbol();
    this.addChild(this.symbol);
    this.multipliers = this.initMultipliers();
    this.addChild(this.multipliers);
    this.currency = setCurrency();
    this.setPayTableData(icon.id, combos);
  }

  initRect(): PIXI.Graphics {
    const rect = new PIXI.Graphics();
    rect.beginFill(PAY_TABLE_BACKGROUND_COLOR);
    rect.alpha = 0.75;
    rect.drawRoundedRect(
      this.isLast ? 0 : SLOT_WIDTH / 2,
      (SLOT_HEIGHT - PAY_TABLE_HEIGHT) / 2,
      PAY_TABLE_WIDTH,
      PAY_TABLE_HEIGHT,
      15,
    );
    rect.zIndex = 2;

    return rect;
  }

  initSymbol(): PIXI.Sprite {
    const symbol = new PIXI.Sprite();
    symbol.x = this.isLast ? SLOT_WIDTH : 0;
    symbol.y = 0;
    symbol.width = SLOT_WIDTH;
    symbol.height = SLOT_HEIGHT;
    symbol.zIndex = 3;

    return symbol;
  }

  initMultipliers(): MultiStyleText {
    const multipliers = new MultiStyleText('x5: 1488', {
      default: {
        ...miniPayTableMultipliersStyle,
        align: 'left',
      },
      span: {
        fill: 0xffffff,
      },
    });

    multipliers.x = this.isLast ? 20 : SLOT_WIDTH;
    multipliers.zIndex = 3;
    multipliers.anchor.set(0, 0.5);

    return multipliers;
  }

  setPayTableData(iconId: SlotId, combos: Combos): void {
    this.iconId = iconId;
    this.combos = combos;

    this.symbol.texture = PIXI.Texture.from(MAPPED_SYMBOLS[iconId]);
    if (iconId === SlotId.WL) {
      this.symbol.width = Math.round(SLOT_WIDTH * WILD_SCALE);
      this.symbol.height = Math.round(SLOT_HEIGHT * WILD_SCALE);

      this.symbol.anchor.set(0.5);
      this.symbol.position.set(this.isLast ? SLOT_WIDTH + SLOT_WIDTH / 2 : SLOT_WIDTH / 2, SLOT_HEIGHT / 2);
    } else {
      this.symbol.scale.set(1);
      this.multipliers.y = SLOT_HEIGHT / 2;
    }

    this.multipliers.text = this.getCombos();
    this.multipliers.y = SLOT_HEIGHT / 2;
    if (iconId === SlotId.WL) {
      this.multipliers.x = SLOT_WIDTH * 1.5;
      this.multipliers.anchor.set(0.5);
      this.multipliers.style = {
        align: 'center',
        fontSize: 30,
        fill: 0xffffff,
        wordWrap: true,
        wordWrapWidth: SLOT_WIDTH + 120,
      };
      if (this.multipliers.width > SLOT_WIDTH - 50) {
        this.multipliers.scale.set((SLOT_WIDTH - 50) / this.multipliers.width);
      }
    } else {
      this.multipliers.x = this.isLast ? 20 : SLOT_WIDTH;
      this.multipliers.anchor.set(0, 0.5);
      this.multipliers.style = {
        align: 'left',
        wordWrap: false,
      };
      this.multipliers.scale.set(1);
    }
  }

  private calcMultiplier(multiplier: number): number {
    return normalizeCoins(setCoinAmount() * multiplier);
  }

  private getCombos(): string {
    return this.iconId !== SlotId.WL
      ? (
          this.combos?.reduce(
            (acc: string, curr: IconCombo) =>
              `${acc} <span>${curr.pattern}:</span> ${formatNumber({
                currency: this.currency,
                value: this.calcMultiplier(curr.multiplier),
                showCurrency: showCurrency(this.currency),
              })}\n`,
            '',
          ) || ''
        ).trimEnd()
      : i18n.t<string>('miniPaytable_wild_description');
  }

  public showPayTable(uniqueId: number): void | undefined {
    if (!setIsMiniPayTable() || (!this.combos?.length && this.iconId !== SlotId.WL)) return;
    if (uniqueId !== this.id) {
      this.visible = false;
      return;
    }

    this.visible = !this.visible;
  }

  private handleChangeBetAmount(): void {
    this.multipliers.text = this.getCombos();
  }
}

export default MiniPayTable;
