import _ from 'lodash';
import * as PIXI from 'pixi.js';

import SlotMachine from '..';
import { MAPPED_SYMBOLS, MAPPED_SYMBOLS_WIN_ANIMATIONS, SlotId, SymbolAnimationType } from '../../config';
import { EventTypes, ISettledBet } from '../../global.d';
import { setGameMode } from '../../gql';
import { isFreeSpinMode } from '../../utils';
import Animation from '../animations/animation';
import AnimationChain from '../animations/animationChain';
import AnimationGroup from '../animations/animationGroup';
import { TweenProperties } from '../animations/d';
import SpineAnimation from '../animations/spine';
import SpriteAnimation from '../animations/sprite';
import Tween from '../animations/tween';
import ViewContainer from '../components/container';
import {
  APPLICATION_FPS,
  REELS_AMOUNT,
  REEL_WIDTH,
  SHOW_ALL_LINES_ON_WIN,
  SLOTS_CONTAINER_HEIGHT,
  SLOTS_CONTAINER_WIDTH,
  SLOTS_PER_REEL_AMOUNT,
  SLOT_HEIGHT,
  SLOT_WIDTH,
  SPRITE_ANIMATION_FPS,
  TURBO_SPIN_WIN_SLOT_ANIMATION_COEFFICIENT,
  WILD_SCALE,
  WIN_SLOT_ANIMATION_DURATION,
  WIN_SLOT_ANIMATION_SCALE,
  eventManager,
} from '../config';
import { IWinLine, Icon, IconType } from '../d';
import Slot from '../slot/slot';

class WinSlotsContainer extends ViewContainer {
  private winSlotsContainer: ViewContainer[];

  private slotsContainer: Slot[][];

  public animation: AnimationChain | null = null;

  public loopAnimation: Animation | null = null;

  constructor() {
    super();
    this.width = SLOTS_CONTAINER_WIDTH;
    this.height = SLOTS_CONTAINER_HEIGHT;
    this.winSlotsContainer = [];
    this.slotsContainer = [];
    this.sortableChildren = true;
    eventManager.addListener(EventTypes.SKIP_WIN_SLOTS_ANIMATION, this.skipWinSlotsAnimation.bind(this));
    eventManager.addListener(EventTypes.START_WIN_ANIMATION, this.onStartWinAnimation.bind(this));
    for (let i = 0; i < REELS_AMOUNT; i++) {
      const slotsArray = [];
      const container = new ViewContainer();
      container.zIndex = REELS_AMOUNT - i;
      container.width = SLOT_WIDTH;
      container.height = SLOT_HEIGHT * SLOTS_PER_REEL_AMOUNT;
      container.x = i * REEL_WIDTH;
      for (let j = 0; j < SLOTS_PER_REEL_AMOUNT; j++) {
        const slot = new Slot(j, SlotId.A);
        slot.anchor.set(0.5, 0.5);
        slot.y += SLOT_HEIGHT / 2;
        slot.visible = false;
        slotsArray.push(slot);
        container.addChild(slot);
      }
      this.slotsContainer.push(slotsArray);
      this.winSlotsContainer.push(container);
      this.addChild(container);
    }
  }

  private onStartWinAnimation(nextResult: ISettledBet, isTurboSpin: boolean) {
    this.showWin(nextResult, isTurboSpin);
  }

  private skipWinSlotsAnimation() {
    this.animation?.skip();
    this.loopAnimation?.skip();
  }

  public highlightSlots(slots: number[], spinResult: Icon[], isTurboSpin: boolean | undefined): AnimationGroup {
    const animationGroup = new AnimationGroup({});
    slots.forEach((slotId, index) => {
      const symbolId = spinResult[slotId].id;
      if (MAPPED_SYMBOLS_WIN_ANIMATIONS[symbolId].type === SymbolAnimationType.SPINE) {
        if (symbolId === SlotId.WL) {
          const { nextResult } = SlotMachine.getInstance();
          if (nextResult?.bet.data.features.multipliers) {
            const multipliers = nextResult?.bet.data.features.multipliers;

            animationGroup.addAnimation(
              this.createSlotSpineAnimation(
                slotId,
                'symbols',
                `wild_win_x${multipliers[slots[index]]}`,
                !!isTurboSpin,
                true,
              ),
            );
          }
        } else {
          animationGroup.addAnimation(
            this.createSlotSpineAnimation(
              slotId,
              MAPPED_SYMBOLS_WIN_ANIMATIONS[symbolId].src!,
              MAPPED_SYMBOLS_WIN_ANIMATIONS[symbolId].animation!,
              !!isTurboSpin,
            ),
          );
        }
      }
      if (MAPPED_SYMBOLS_WIN_ANIMATIONS[symbolId].type === SymbolAnimationType.SPRITE) {
        const sheet = this.getSlotAnimationSheet(symbolId)!;
        animationGroup.addAnimation(this.createSlotSpriteAnimation(sheet, slotId, isTurboSpin));
      }

      if (MAPPED_SYMBOLS_WIN_ANIMATIONS[symbolId].type === SymbolAnimationType.DEFAULT) {
        const slot = this.slotsContainer[slotId % 5][Math.floor(slotId / 5)];
        slot.texture = PIXI.Texture.from(MAPPED_SYMBOLS[symbolId]);
        slot.visible = false;
        animationGroup.addAnimation(this.createSlotScaleAnimation(slot, isTurboSpin));
        animationGroup.addOnStart(() => {
          slot.visible = true;
        });
      }
    });
    animationGroup.addOnStart(() => {
      eventManager.emit(EventTypes.SET_SLOTS_VISIBILITY, [...slots], false);
    });
    animationGroup.addOnComplete(() => {
      eventManager.emit(EventTypes.SET_SLOTS_VISIBILITY, [...slots], true);
      this.hideAllSlots();
    });
    animationGroup.addOnSkip(() => {
      eventManager.emit(EventTypes.SET_SLOTS_VISIBILITY, [...slots], true);
      this.hideAllSlots();
    });
    return animationGroup;
  }

  private createSlotSpineAnimation(
    id: number,
    srcName: string,
    animationName: string,
    isTurboSpine: boolean,
    isWild = false,
  ): Animation {
    const dummy = Tween.createDelayAnimation(2000);
    let animation: SpineAnimation | undefined;
    dummy.addOnStart(() => {
      animation = new SpineAnimation({}, PIXI.Loader.shared.resources[srcName].spineData!);
      if (isWild) {
        animation.spine.scale.set(WILD_SCALE);
      }
      animation.spine.x = REEL_WIDTH / 2;
      animation.spine.y = SLOT_HEIGHT * Math.floor(id / 5) + SLOT_HEIGHT / 2;
      this.winSlotsContainer[id % 5].addChild(animation.spine);
      animation.spine.state.setAnimation(0, animationName, false);
    });

    dummy.addOnComplete(() => {
      if (animation?.spine) this.winSlotsContainer[id % 5].removeChild(animation.spine);
    });
    dummy.addOnSkip(() => {
      if (animation?.spine) this.winSlotsContainer[id % 5].removeChild(animation.spine);
    });
    return dummy;
  }

  private getSlotAnimationSheet(slotId: SlotId): PIXI.Spritesheet | undefined {
    if (_.isEmpty(MAPPED_SYMBOLS_WIN_ANIMATIONS[slotId])) return undefined;

    return _.get(PIXI.Loader.shared.resources, MAPPED_SYMBOLS_WIN_ANIMATIONS[slotId].src!).spritesheet;
  }

  private createSlotSpriteAnimation(sheet: PIXI.Spritesheet, id: number, isTurboSpin: boolean | undefined): Animation {
    const animatedSprite = new SpriteAnimation({}, Object.values(sheet?.textures));
    animatedSprite.spriteAnimation.animationSpeed =
      (isTurboSpin ? SPRITE_ANIMATION_FPS * TURBO_SPIN_WIN_SLOT_ANIMATION_COEFFICIENT : SPRITE_ANIMATION_FPS) /
      APPLICATION_FPS;
    animatedSprite.spriteAnimation.x = SLOT_WIDTH / 2;
    animatedSprite.spriteAnimation.y = SLOT_HEIGHT * Math.floor(id / 5) + SLOT_HEIGHT / 2;
    const container = this.winSlotsContainer[id % 5];
    animatedSprite.addOnStart(() => {
      container.addChild(animatedSprite.spriteAnimation);
    });
    animatedSprite.addOnSkip(() => {
      container.removeChild(animatedSprite.spriteAnimation);
    });
    animatedSprite.addOnComplete(() => {
      container.removeChild(animatedSprite.spriteAnimation);
    });
    return animatedSprite;
  }

  private createSlotScaleAnimation(sprite: PIXI.Sprite, isTurboSpin: boolean | undefined): AnimationGroup {
    const animation: AnimationGroup = new AnimationGroup({});
    const { x, y } = sprite.scale;
    const animationChainX = new AnimationChain();
    const animationDuration = isTurboSpin ? WIN_SLOT_ANIMATION_DURATION / 4 : WIN_SLOT_ANIMATION_DURATION / 2;
    animationChainX.appendAnimation(
      new Tween({
        object: sprite.scale,
        property: TweenProperties.X,
        propertyBeginValue: x,
        target: x * WIN_SLOT_ANIMATION_SCALE,
        duration: animationDuration,
      }),
    );
    animationChainX.appendAnimation(
      new Tween({
        object: sprite.scale,
        property: TweenProperties.X,
        propertyBeginValue: x * WIN_SLOT_ANIMATION_SCALE,
        target: x,
        duration: animationDuration,
      }),
    );
    const animationChainY = new AnimationChain();
    animationChainY.appendAnimation(
      new Tween({
        object: sprite.scale,
        property: TweenProperties.Y,
        propertyBeginValue: y,
        target: y * WIN_SLOT_ANIMATION_SCALE,
        duration: animationDuration,
      }),
    );
    animationChainY.appendAnimation(
      new Tween({
        object: sprite.scale,
        property: TweenProperties.Y,
        propertyBeginValue: y * WIN_SLOT_ANIMATION_SCALE,
        target: y,
        duration: animationDuration,
      }),
    );
    animation.addAnimation(animationChainX);
    animation.addAnimation(animationChainY);
    return animation;
  }

  public hideAllSlots(): void {
    for (let i = 0; i < this.slotsContainer.length; i++) {
      for (let j = 0; j < this.slotsContainer[i].length; j++) {
        this.slotsContainer[i][j].visible = false;
        this.slotsContainer[i][j].scale.set(1, 1);
      }
    }
  }

  private showWin(nextResult: ISettledBet, isTurboSpin: boolean | undefined): void {
    const paylines = nextResult.paylines.filter(
      (payline) => payline.winPositions.length > 1, // Filter out paylines that are not paylines but multipliers for Wild
    );
    const { spinResult } = nextResult.bet.result;

    if (isFreeSpinMode(setGameMode())) {
      const wildPositions = Object.keys(nextResult.bet.data.features.multipliers).map((key) => Number(key));

      wildPositions.forEach((position) => {
        // Substitute real reel symbol for Sticky Wild
        spinResult[position] = {
          ...spinResult[position],
          id: SlotId.WL,
          type: IconType.WILD,
        };
      });
    }

    this.animation = new AnimationChain();
    const set = new Set<number>();
    paylines.forEach((payline) => {
      payline.winPositions.forEach((position) => {
        set.add(position);
      });
    });
    const allSlotsHighlight = this.highlightSlots(
      Array.from(set).sort((a, b) => a - b),
      spinResult,
      isTurboSpin,
    );
    this.animation.addOnStart(() => {
      eventManager.emit(EventTypes.SHOW_TINT, true);
    });
    allSlotsHighlight.addOnStart(() => {
      eventManager.emit(EventTypes.SHOW_WIN_LINES, paylines);
    });
    allSlotsHighlight.addOnComplete(() => {
      eventManager.emit(EventTypes.HIDE_WIN_LINES, paylines);
      this.hideAllSlots();
    });
    allSlotsHighlight.addOnSkip(() => {
      eventManager.emit(EventTypes.HIDE_WIN_LINES, paylines);
      eventManager.emit(EventTypes.SHOW_TINT, false);
      this.hideAllSlots();
    });
    if (SHOW_ALL_LINES_ON_WIN) this.animation.appendAnimation(allSlotsHighlight);
    const animationChain = this.createHighlightChainAnimation(paylines, spinResult, isTurboSpin, false);
    this.loopAnimation = this.createHighlightChainAnimation(paylines, spinResult, isTurboSpin, true);
    this.loopAnimation.addOnSkip(() => {
      eventManager.emit(EventTypes.SHOW_TINT, false);
      this.hideAllSlots();
    });
    if (paylines.length > 1) this.animation.appendAnimation(animationChain);
    animationChain.addOnSkip(() => {
      eventManager.emit(EventTypes.HIDE_WIN_LINES);
      eventManager.emit(EventTypes.SHOW_TINT, false);
    });
    animationChain.addOnComplete(() => {
      eventManager.emit(EventTypes.HIDE_WIN_LINES);
    });
    this.animation.addOnComplete(() => this.loopAnimation?.start());
    this.animation.start();
  }

  public createHighlightChainAnimation(
    paylines: IWinLine[],
    spinResult: Icon[],
    isTurboSpin: boolean | undefined,
    isLoop: boolean,
  ): Animation {
    const animationChain = new AnimationChain({ isLoop });
    paylines.forEach((payline) => {
      const chain = this.highlightSlots(payline.winPositions, spinResult, isTurboSpin);
      chain.addOnStart(() => {
        eventManager.emit(EventTypes.SHOW_WIN_LINES, [payline]);
      });
      chain.addOnComplete(() => {
        eventManager.emit(EventTypes.HIDE_WIN_LINES, [payline]);
      });
      chain.addOnSkip(() => {
        eventManager.emit(EventTypes.HIDE_WIN_LINES, [payline]);
      });
      animationChain!.appendAnimation(chain);
    });
    return animationChain;
  }
}

export default WinSlotsContainer;
