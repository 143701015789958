import _ from 'lodash';

import { AudioSprite } from '@phoenix7dev/play-music/dist/d';

export enum ISongs {
  BaseGameBGM_Base = '007_01_BGM_BG_Base_Loop',
  BaseGameBGM_Melo = '007_02_BGM_BG_Melo_Loop',
  // FreeSpinBGM_Intro = '002_03_FreeSpinBGM_Intro',
  PickemBonusBGM_Loop = '007_03_BGM_PB_Loop',
  FreeSpinBGM_Loop = '007_04_BGM_FS_Loop',
  PickemBonusEntrancePopup = '007_05_PB_EntrancePopup',
  FreeSpinEntrancePopup = '007_06_FS_EntrancePopup',
  FreeSpinExitPopup = '007_07_FS_ExitPopup', // Equals TotalWinBanner
  // BigWin_Intro = '002_04_BigWin_Intro',
  BigWin_Loop = '007_08_BigWin_Loop',
  BigWin_End = '007_09_BigWin_End',
  SFX_WIN_BIG = '007_10_Win_Big',
  SFX_WIN_MEGA = '007_11_Win_Mega',
  SFX_WIN_GREAT = '007_12_Win_Great',
  SFX_WIN_EPIC = '007_13_Win_Epic',
  Small_Win = '007_14_SmallWin',
  Medium_Win = '007_15_MediumWin',
  High_Win = '007_16_HighWin',
  Scatter_01 = '007_17_Scatter_01',
  Scatter_02 = '007_17_Scatter_02',
  Scatter_03 = '007_17_Scatter_03',
  Wild_Landing = '007_20_WildLanding',
  FeatureTrigger = '007_21_FeatureTrigger',
  LongSpin = '007_22_Anticipation',
  PickemBonusShot = '007_23_PB_Pick_Shoot_Bottle',
  FreeSpinsWildLanding = '007_26_PB_WildStick',
  BuyButton = '007_27_BuyButton',
  Win_End = 'SFX_SM_CountUp_End',
  Win_Loop = 'SFX_SM_CountUp_Loop',
  SFX_UI_AutoSpin = 'SFX_UI_AutoSpin',
  SFX_UI_BetChange = 'SFX_UI_BetChange',
  SFX_UI_Close = 'SFX_UI_Close',
  SFX_UI_General = 'SFX_UI_General',
  SFX_UI_Hover = 'SFX_UI_Hover',
  SFX_UI_MaxBet = 'SFX_UI_MaxBet',
  SFX_UI_MenuOpen = 'SFX_UI_MenuOpen',
  SFX_UI_SpinStart = 'SFX_UI_SpinStart',
  SFX_UI_SpinStop = 'SFX_UI_SpinStop',
}

export const audioSprite: AudioSprite = {
  [ISongs.BaseGameBGM_Base]: [0, 66674.64852607709, true],
  [ISongs.BaseGameBGM_Melo]: [68000, 66667.39229024944, true],
  // [ISongs.FreeSpinBGM_Intro]: [124000, 18000],
  [ISongs.PickemBonusBGM_Loop]: [136000, 60633.10657596372, true],
  [ISongs.FreeSpinBGM_Loop]: [198000, 84001.08843537413, true],
  [ISongs.PickemBonusEntrancePopup]: [284000, 3603.446712018126],
  [ISongs.FreeSpinEntrancePopup]: [289000, 4053.3333333333417],
  [ISongs.FreeSpinExitPopup]: [295000, 2834.285714285727],
  // [ISongs.BigWin_Intro]: [210000, 888.9342403628007],
  [ISongs.BigWin_Loop]: [299000, 19864.671201814075, true],
  [ISongs.BigWin_End]: [320000, 4967.619047619053],
  [ISongs.SFX_WIN_BIG]: [326000, 3316.0997732426267],
  [ISongs.SFX_WIN_MEGA]: [331000, 3321.9047619047615],
  [ISongs.SFX_WIN_GREAT]: [336000, 3316.0997732426267],
  [ISongs.SFX_WIN_EPIC]: [341000, 3333.514739229031],
  [ISongs.Small_Win]: [346000, 1888.0725623582748],
  [ISongs.Medium_Win]: [349000, 1888.0725623582748],
  [ISongs.High_Win]: [352000, 3156.462585034035],
  [ISongs.Scatter_01]: [357000, 1269.8412698412653],
  [ISongs.Scatter_02]: [360000, 1269.8412698412653],
  [ISongs.Scatter_03]: [363000, 1269.8412698412653],
  [ISongs.Wild_Landing]: [366000, 2523.7188208616885],
  [ISongs.FeatureTrigger]: [370000, 3780.498866213179],
  [ISongs.LongSpin]: [375000, 2218.956916099785],
  [ISongs.PickemBonusShot]: [379000, 1264.0362811791306],
  [ISongs.FreeSpinsWildLanding]: [382000, 933.1519274376205],
  [ISongs.BuyButton]: [384000, 938.9569160997553],
  [ISongs.Win_End]: [386000, 933.4693877551103],
  [ISongs.Win_Loop]: [388000, 399.77324263037417, true],
  [ISongs.SFX_UI_AutoSpin]: [390000, 320.4988662131427],
  [ISongs.SFX_UI_BetChange]: [392000, 32.108843537400844],
  [ISongs.SFX_UI_Close]: [394000, 200.68027210885475],
  [ISongs.SFX_UI_General]: [396000, 88.34467120180989],
  [ISongs.SFX_UI_Hover]: [398000, 151.79138321997243],
  [ISongs.SFX_UI_MaxBet]: [400000, 122.94784580501528],
  [ISongs.SFX_UI_MenuOpen]: [402000, 277.1428571428487],
  [ISongs.SFX_UI_SpinStart]: [404000, 249.61451247168043],
  [ISongs.SFX_UI_SpinStop]: [406000, 151.02040816327644],
};

export const audioSpriteVolume: Record<ISongs, number> = {
  [ISongs.BaseGameBGM_Base]: 0.2,
  [ISongs.BaseGameBGM_Melo]: 0.35,
  // [ISongs.FreeSpinBGM_Intro]: 0.3,
  [ISongs.PickemBonusBGM_Loop]: 0.25,
  [ISongs.FreeSpinBGM_Loop]: 0.25,
  [ISongs.PickemBonusEntrancePopup]: 0.5,
  [ISongs.FreeSpinEntrancePopup]: 0.5,
  [ISongs.FreeSpinExitPopup]: 0.5,
  // [ISongs.BigWin_Intro]: 0.3,
  [ISongs.BigWin_Loop]: 0.5,
  [ISongs.BigWin_End]: 0.4,
  [ISongs.SFX_WIN_BIG]: 0.4,
  [ISongs.SFX_WIN_EPIC]: 0.4,
  [ISongs.SFX_WIN_GREAT]: 0.4,
  [ISongs.SFX_WIN_MEGA]: 0.4,
  [ISongs.Small_Win]: 0.4,
  [ISongs.Medium_Win]: 0.4,
  [ISongs.High_Win]: 0.4,
  [ISongs.Scatter_01]: 0.5,
  [ISongs.Scatter_02]: 0.5,
  [ISongs.Scatter_03]: 0.5,
  [ISongs.Wild_Landing]: 0.5,
  [ISongs.FeatureTrigger]: 0.4,
  [ISongs.LongSpin]: 0.4,
  [ISongs.PickemBonusShot]: 0.4,
  [ISongs.FreeSpinsWildLanding]: 0.5,
  [ISongs.BuyButton]: 0.4,
  [ISongs.Win_End]: 0.4,
  [ISongs.Win_Loop]: 0.5,
  [ISongs.SFX_UI_AutoSpin]: 0.4,
  [ISongs.SFX_UI_BetChange]: 0.4,
  [ISongs.SFX_UI_Close]: 0.4,
  [ISongs.SFX_UI_General]: 0.4,
  [ISongs.SFX_UI_Hover]: 0.4,
  [ISongs.SFX_UI_MaxBet]: 0.4,
  [ISongs.SFX_UI_MenuOpen]: 0.4,
  [ISongs.SFX_UI_SpinStart]: 0.3,
  [ISongs.SFX_UI_SpinStop]: 0.3,
};

export const audioSpriteGaps: { [key in ISongs]?: number } = {
  // [ISongs.Music_BigWin_End]: 2000,
};

export const mappedAudioSprites = _.reduce(
  audioSprite,
  (acc, value, key) => {
    _.set(acc, key, {
      start: value[0],
      duration: value[1] - (audioSpriteGaps[key as ISongs] || 0),
      loop: !!value[2],
    });
    return acc;
  },
  {},
) as {
  [key in ISongs]: { start: number; duration: number; loop: boolean };
};
