import * as PIXI from 'pixi.js';

import {
  CAROUSEL_DOTS_ACTIVE_COLOR,
  CAROUSEL_DOTS_BLUR_SIZE,
  CAROUSEL_DOTS_DEFAULT_COLOR,
  CAROUSEL_DOTS_SIZE,
} from './config';

export class Dot extends PIXI.Sprite {
  protected active: boolean;

  private index: string;

  private resolution: number;

  private dirty: boolean;

  public canvas: HTMLCanvasElement | null;

  public context: CanvasRenderingContext2D | null;

  constructor(index: string, active = false) {
    const canvas = document.createElement('canvas');
    const texture = PIXI.Texture.from(canvas);
    texture.orig = new PIXI.Rectangle(
      0,
      0,
      CAROUSEL_DOTS_SIZE + CAROUSEL_DOTS_BLUR_SIZE * 2,
      CAROUSEL_DOTS_SIZE + CAROUSEL_DOTS_BLUR_SIZE * 2,
    );

    super(texture);

    this.canvas = canvas;
    this.context = canvas.getContext('2d');
    this.index = index;
    this.active = active;
    this.resolution = PIXI.settings.RESOLUTION;
    this.name = `dot${this.index}`;
    this.hitArea = new PIXI.Rectangle(
      CAROUSEL_DOTS_BLUR_SIZE,
      CAROUSEL_DOTS_BLUR_SIZE,
      CAROUSEL_DOTS_SIZE,
      CAROUSEL_DOTS_SIZE,
    );
    this.pivot.set(CAROUSEL_DOTS_BLUR_SIZE, CAROUSEL_DOTS_BLUR_SIZE);

    this.dirty = true;
  }

  private updateTexture() {
    if (!this.dirty) return;

    const canvas = this.canvas!;
    const ctx = this.context!;

    canvas.width = (CAROUSEL_DOTS_SIZE + CAROUSEL_DOTS_BLUR_SIZE * 2) * this.resolution;
    canvas.height = (CAROUSEL_DOTS_SIZE + CAROUSEL_DOTS_BLUR_SIZE * 2) * this.resolution;

    ctx.scale(this.resolution, this.resolution);
    ctx.translate(CAROUSEL_DOTS_BLUR_SIZE, CAROUSEL_DOTS_BLUR_SIZE);
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // circle
    ctx.save();
    ctx.beginPath();
    ctx.arc(CAROUSEL_DOTS_SIZE / 2, CAROUSEL_DOTS_SIZE / 2, CAROUSEL_DOTS_SIZE / 2, 0, 2 * Math.PI);
    ctx.fillStyle = this.active ? CAROUSEL_DOTS_ACTIVE_COLOR : CAROUSEL_DOTS_DEFAULT_COLOR;
    ctx.shadowColor = 'black';
    ctx.shadowBlur = CAROUSEL_DOTS_BLUR_SIZE;
    ctx.fill();
    ctx.restore();

    // text
    ctx.save();
    ctx.fillStyle = 'black';
    ctx.font = '14px Arial';
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.fillText(this.index, CAROUSEL_DOTS_SIZE / 2, CAROUSEL_DOTS_SIZE / 2 + 1);
    ctx.restore();

    this._onTextureUpdate();
    this.texture.baseTexture.setRealSize(canvas.width, canvas.height, this.resolution);
    this._recursivePostUpdateTransform();

    this.dirty = false;
  }

  protected _render(renderer: PIXI.Renderer): void {
    if (this.resolution !== renderer.resolution) {
      this.resolution = renderer.resolution;
      this.dirty = true;
    }
    this.updateTexture();
    super._render(renderer);
  }

  public destroy(options?: { children?: boolean; texture?: boolean; baseTexture?: boolean }): void {
    options = {
      texture: true,
      children: false,
      baseTexture: true,
      ...options,
    };

    super.destroy(options);

    this.canvas!.width = 0;
    this.canvas!.height = 0;

    this.context = null;
    this.canvas = null;
  }

  public setActive(active: boolean): void {
    if (this.active !== active) {
      this.dirty = true;
      this.active = active;
    }
  }
}
