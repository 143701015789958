const font = 'NotoSans-SemiCondensedBold';

export const titleStyle = {
  default: {
    fontSize: 58,
    fontFamily: font,
    fill: '#010101',
    letterSpacing: 0,
    align: 'center',
  },
  span: {
    fill: '#b00c03',
  },
};

export const subtitleStyle = {
  fontSize: 50,
  fontFamily: font,
};
export const additionalStyle = {
  fontSize: 40,
  fontFamily: font,
};

export const winTitleStyle = {
  fontSize: 80,
  fontFamily: font,
};

export const winSubtitleStyle = {
  fontSize: 120,
  fontFamily: font,
};

export const btnStyle = {
  default: {
    fontSize: 36,
    fontFamily: font,
    fill: '#010101',
    align: 'center',
  },
};
