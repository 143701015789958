import i18n from 'i18next';
import * as PIXI from 'pixi.js';

import AudioHowl from '@phoenix7dev/play-music';

import { ISongs } from '../../config';
import { EventTypes, GameMode } from '../../global.d';
import {
  setBrokenGame,
  setIsProcessToGame,
  setIsShowSoundToast,
  setIsSoundLoading,
  setIsSoundOn,
  setProgress,
  setSkipIntroScreen,
  setSoundValue,
} from '../../gql';
import { setBrokenPickem, setGameMode } from '../../gql/cache';
import { ResourceTypes } from '../../resources.d';
import Switch from '../../slotMachine/button/switch';
import { APPLICATION_TRANSPARENT, eventManager } from '../../slotMachine/config';
import { dropShadowFilter, nextTick } from '../../utils';

import Carousel from './Carousel/index';

const GAP_Y = 15;
const GAP_X = 15;

class IntroScreen {
  private readonly application: PIXI.Application;

  private container: PIXI.Container;

  private controlsContainer: PIXI.Container;

  private static introScreen: IntroScreen;

  public static initIntroScreen = (application: PIXI.Application): void => {
    IntroScreen.introScreen = new IntroScreen(application);
  };

  public static getInstance = (): IntroScreen | null => IntroScreen.introScreen;

  private background = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.introBg));

  private backgroundContainer = new PIXI.Container();

  private okBtn: PIXI.Sprite;

  private soundSwitch: Switch;

  private skipIntroSwitch: Switch;

  private carousel: Carousel;

  private bindedResize = this.resize.bind(this);

  private constructor(application: PIXI.Application) {
    const introContents = [
      { img: ResourceTypes.introReel1, txt: i18n.t('introTitle') },
      { img: ResourceTypes.introReel2, txt: i18n.t('introTitle2') },
      { img: ResourceTypes.introReel3, txt: i18n.t('introTitle3') },
    ];

    this.application = application;
    this.background.anchor.set(0.5);
    this.backgroundContainer.addChild(this.background);
    this.application.stage.addChild(this.backgroundContainer);
    this.container = new PIXI.Container();
    this.container.sortableChildren = true;
    this.controlsContainer = new PIXI.Container();
    this.carousel = new Carousel(500, introContents);
    this.carousel.zIndex = 2;
    this.container.addChild(this.carousel);
    this.okBtn = this.initOkBtn();
    this.controlsContainer.addChild(this.okBtn);
    this.soundSwitch = this.initSoundSwitchBtn();
    this.controlsContainer.addChild(this.soundSwitch);
    this.skipIntroSwitch = this.initSkipIntroSwitchBtn();
    this.controlsContainer.addChild(this.skipIntroSwitch);
    this.container.addChild(this.controlsContainer);
    this.application.stage.addChild(this.container);
    eventManager.addListener(EventTypes.RESIZE, this.bindedResize);
  }

  public getApplication(): PIXI.Application {
    return this.application;
  }

  private initOkBtn = (): PIXI.Sprite => {
    const texture = PIXI.Texture.from(ResourceTypes.buttonOk);
    const btn = new PIXI.Sprite(texture);
    btn.anchor.set(0, 0);
    btn.width = 200;
    btn.height = 50;
    btn.y = 0;
    btn.x = 0;
    btn.buttonMode = true;
    btn.interactive = true;

    const dropShadow = dropShadowFilter({
      color: 0x000000,
      resolution: 4 * PIXI.settings.FILTER_RESOLUTION,
      alpha: 0.4,
    }) as PIXI.Filter;
    const clickCallback = () => {
      let soundToPlay;

      if (setBrokenPickem()) {
        soundToPlay = [{ type: ISongs.PickemBonusBGM_Loop }];
      } else if (setBrokenGame() && setGameMode() === GameMode.FREE_SPINS) {
        soundToPlay = [{ type: ISongs.FreeSpinBGM_Loop }];
      } else {
        soundToPlay = [{ type: ISongs.BaseGameBGM_Base }, { type: ISongs.BaseGameBGM_Melo, volume: 0 }];
      }

      AudioHowl.restrictionChangedOnIntroScreen = true;
      if (!AudioHowl.isInitialized) {
        eventManager.emit(EventTypes.ENABLE_SOUND_LOADER);
      }
      AudioHowl.changeRestriction(
        false,
        soundToPlay,
        () => setIsSoundLoading(true),
        () => setIsShowSoundToast(false),
      );

      setProgress({ ...setProgress(), wasLoaded: true });

      nextTick(() => {
        this.destroy();
        eventManager.emit(EventTypes.HANDLE_DESTROY_INTRO_SCREEN);
      });
    };
    btn.on('mousedown', () => {
      btn.filters = [dropShadow];
      btn.height = 49;
    });
    btn.on('mouseout', () => {
      btn.filters = [];
      btn.height = 50;
    });
    btn.on('touchstart', () => {
      btn.filters = [dropShadow];
      btn.height = 49;
    });
    btn.on('touchendoutside', () => {
      btn.filters = [];
      btn.height = 50;
    });
    btn.on('click', clickCallback);
    btn.on('touchend', clickCallback);

    return btn;
  };

  private initSoundSwitchBtn = (): Switch => {
    const btn = new Switch({
      label: i18n.t('sound'),
      textMaxWidth: 500,
      textMobileMaxWidth: 120,
      textMaxHeight: 90,
      textMobileMaxHeight: 50,
    });

    btn.y = this.okBtn.height + GAP_Y;
    btn.setActive(setIsSoundOn());
    btn.interactive = true;
    btn.on('pointerdown', () => {
      setIsSoundOn(!setIsSoundOn());
      AudioHowl.setSoundState(!setIsSoundOn());
      setSoundValue(!setIsSoundOn() ? 1 : 0);
    });
    return btn;
  };

  private initSkipIntroSwitchBtn = (): Switch => {
    const btn = new Switch({
      label: i18n.t('show'),
      textMaxWidth: 500,
      textMobileMaxWidth: 120,
      textMaxHeight: 90,
      textMobileMaxHeight: 50,
    });
    btn.y = this.okBtn.height + GAP_Y;
    btn.x = this.soundSwitch.width + GAP_X;
    btn.interactive = true;
    btn.on('pointerdown', () => {
      setSkipIntroScreen(!setSkipIntroScreen());
    });

    return btn;
  };

  private setBgSize = (width: number, height: number): void => {
    this.backgroundContainer.x = width / 2;
    this.backgroundContainer.y = height / 2;

    const bgAspectRatio = this.background.width / this.background.height;
    const aspectRatio = width / height;
    if (bgAspectRatio > aspectRatio) {
      this.backgroundContainer.scale.set(height / this.background.height);
    } else {
      this.backgroundContainer.scale.set(width / this.background.width);
    }
  };

  private setControlsPositions = (width: number, height: number): void => {
    this.okBtn.x = (this.controlsContainer.width - this.okBtn.width) / 2;
    this.controlsContainer.y = this.carousel.height + GAP_Y;
    this.controlsContainer.x = width / 2 - this.controlsContainer.width / 2;
  };

  private resize(width: number, height: number): void {
    this.setBgSize(width, height);
    this.carousel.setSize(width, height, this.controlsContainer.height + GAP_Y * 3);

    this.setControlsPositions(width, height);
    this.container.y = height / 2 - this.container.height / 2;
    this.application.renderer.resize(width, height);
  }

  private destroy(): void {
    this.application.stage.removeChild(this.backgroundContainer);
    this.application.stage.removeChild(this.container);
    setIsProcessToGame(true);
    eventManager.removeListener(EventTypes.RESIZE, this.bindedResize);
  }
}

export default IntroScreen;
