import { GAME_CONTAINER_HEIGHT, GAME_CONTAINER_WIDTH } from '../config';

export const BANNER_WIDTH = 1044;
export const BANNER_HEIGHT = 806;
export const BANNER_POSITION_X = GAME_CONTAINER_WIDTH / 2 - BANNER_WIDTH / 2;
export const BANNER_POSITION_Y = 150;

// export const BANNER_BACKGROUND_COLOR = 0xffffff;

export const titlePosition = 211;
export const subtitlePosition = 160;
export const additionalPosition = 240;

export const winTitlePosition = 100;
export const winSubtitlePosition = 230;

export const buttonPosition = 566;
