import { t } from 'i18next';
import _ from 'lodash';
import * as PIXI from 'pixi.js';

import AudioHowl from '@phoenix7dev/play-music';

import { ISongs } from '../../config';
import { BetBonusReward, EventTypes, GameMode, ISettledBet, PreLoadedGrantBreakdown, reelSets } from '../../global.d';
import {
  setCurrentBonus,
  setCurrentBonusId,
  setIsPickemShotInProgress,
  setIsShowSoundToast,
  setIsSoundLoading,
} from '../../gql';
import i18n from '../../i18next';
import { ResourceTypes } from '../../resources.d';
import { getSoundToPlay, isMobileDevice, updateTextScale } from '../../utils';
import SpineAnimation from '../animations/spine';
import { TextField } from '../components/TextField';
import {
  PICKEM_AWARD_HEIGHT,
  PICKEM_AWARD_WIDTH,
  PICKEM_CONTAINER_X,
  PICKEM_CONTAINER_Y,
  PICKEM_FRAME_HEIGHT,
  PICKEM_FRAME_POSITION_X,
  PICKEM_FRAME_POSITION_Y,
  PICKEM_FRAME_WIDTH,
  REELS_AMOUNT,
  SLOTS_CONTAINER_HEIGHT,
  SLOTS_CONTAINER_WIDTH,
  SLOTS_PER_REEL_AMOUNT,
  eventManager,
} from '../config';
import { buttonPosition, titlePosition } from '../messageBanner/config';
import { btnStyle, titleStyle } from '../messageBanner/textStyles';

import Pickem from './pickem';

class PickemContainer extends PIXI.Container {
  private animation: SpineAnimation | null = null;

  public frame: PIXI.Sprite;

  public patronsContainer = new PIXI.Container();

  private titleText: TextField;

  private titleValue: PIXI.Text;

  private round: number;

  private rounds: number;

  private freeSpinsValue: number;

  private grants: PreLoadedGrantBreakdown[];

  private isClicked: boolean;

  constructor(grants: PreLoadedGrantBreakdown[]) {
    super();
    this.width = SLOTS_CONTAINER_WIDTH;
    this.height = SLOTS_CONTAINER_HEIGHT;
    this.sortableChildren = true;
    this.isClicked = false;
    this.round = 0;
    this.rounds = grants.length;
    this.grants = grants;
    this.freeSpinsValue = 0;
    this.frame = this.initReelsFrame();
    this.titleText = this.initTitleText();
    this.titleValue = this.initTitleValue(`${this.freeSpinsValue}`);
    this.addChild(this.frame);
    this.addChild(this.titleText.getText());
    this.addChild(this.titleValue);
    this.createContainers();
    this.initAnimation();
    this.addChild(this.patronsContainer);
    this.initPatrons();
    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));
    eventManager.on(EventTypes.PICKEM_SHOT_RESOLVED, (placeBet: ISettledBet) => {
      setCurrentBonus({
        ...placeBet!.bet.data.bonuses[0],
        isActive: true,
        currentRound: 0,
        rounds: this.freeSpinsValue,
      });
      setCurrentBonusId((placeBet?.rewards[1] as BetBonusReward).userBonusId);

      this.createFinishPopup(this.freeSpinsValue);
    });
  }

  private initReelsFrame(): PIXI.Sprite {
    const frame = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.pickemFrame));
    frame.height = PICKEM_FRAME_HEIGHT;
    frame.width = PICKEM_FRAME_WIDTH;
    frame.y = PICKEM_FRAME_POSITION_Y;
    frame.x = PICKEM_FRAME_POSITION_X;
    return frame;
  }

  private initPatrons(): void {
    this.patronsContainer.x = 545;
    this.patronsContainer.y = 1030;

    for (let i = 0; i < this.rounds; i++) {
      const patron = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.patron));
      patron.y = 0;
      patron.x = patron.width * i;
      this.patronsContainer.addChild(patron);
    }
  }

  private initTitleText(): TextField {
    const text = new TextField(i18n.t<string>('freeSpinsTitle'), 250, 250, {
      fontSize: 36,
      fontFamily: 'NotoSans-SemiCondensedBold',
      fill: '0x160702',
    });
    text.text.y = 30;
    text.text.x = SLOTS_CONTAINER_WIDTH / 2;
    text.text.anchor.set(0.5, 0.5);

    return text;
  }

  private initTitleValue(freeSpinsValue: string): PIXI.Text {
    const text = new PIXI.Text(freeSpinsValue, {
      fontSize: 71,
      fontFamily: 'NotoSans-SemiCondensedBold',
      fill: '0xfac500',
    });
    text.y = 60;
    text.x = SLOTS_CONTAINER_WIDTH / 2;
    text.anchor.set(0.5, 0);

    return text;
  }

  private createContainers(): void {
    const container = new PIXI.Container();
    container.y = PICKEM_CONTAINER_Y;
    container.x = PICKEM_CONTAINER_X;
    for (let row = 0; row < SLOTS_PER_REEL_AMOUNT; row++) {
      for (let col = 0; col < REELS_AMOUNT; col++) {
        const pickem = new Pickem(row * REELS_AMOUNT + col);
        pickem.x = col * PICKEM_AWARD_WIDTH;
        pickem.y = PICKEM_AWARD_HEIGHT * row;
        pickem.zIndex = 1;
        pickem.interactive = true;
        pickem.on('pointerdown', (): void => {
          if (this.round < this.rounds && pickem.awardSymbol.visible && !this.isClicked) {
            this.isClicked = true;
            const { count } = this.grants[this.round].grants[0].grants[0];
            this.freeSpinsValue += pickem.handlePickem(count);
            setTimeout(() => {
              this.titleValue.text = `${this.freeSpinsValue}`;
              this.isClicked = false;
            }, 1000);
            AudioHowl.play({ type: ISongs.PickemBonusShot, stopPrev: true });
            this.startAnimation(col);
            this.patronsContainer.removeChildAt(0);
            this.round += 1;

            if (this.round === this.grants.length) {
              eventManager.emit(EventTypes.PICKEM_SHOT);
              setIsPickemShotInProgress(true);
            }

            if (AudioHowl.isRestricted) {
              const soundToPlay = getSoundToPlay();

              AudioHowl.changeRestriction(
                false,
                soundToPlay,
                () => setIsSoundLoading(true),
                () => setIsShowSoundToast(false),
              );
            }
          }
        });
        container.addChild(pickem);
        this.addChild(container);
      }
    }
  }

  initAnimation(): void {
    this.animation = new SpineAnimation({}, PIXI.Loader.shared.resources.pickem_bonus.spineData!);
    this.animation.spine.x = 771;
    this.animation.spine.y = 684;
    this.animation!.setAnimation('all_hand', false);
    this.addChild(this.animation!.spine);
  }

  private startAnimation(id: number): void {
    const anim = id < 2 ? 'right_hand_fire' : 'left_hand_fire';

    this.animation!.setAnimation(anim, false);
    this.animation!.start();
    this.animation!.complete = [];
  }

  private createFinishPopup(freeSpinsValue: number): void {
    // Used FreeSpinEntrancePopup as PickemBonusExitPopup cause there is no Free Spins entrance popup and no sound for PickemBonusExitPopup
    AudioHowl.play({ type: ISongs.FreeSpinEntrancePopup, stopPrev: true });
    eventManager.emit(EventTypes.CREATE_MESSAGE_BANNER, {
      bannerBg: 'free_spins_pop_up',
      title: {
        text: t('pickemWinMessageBannerText', { freeSpinsValue }),
        position: titlePosition,
        styles: titleStyle,
      },
      btn: {
        text: 'pickemMessageBannerBtnText',
        position: buttonPosition,
        styles: btnStyle,
      },
      callback: () => {
        eventManager.emit(EventTypes.START_MODE_CHANGE_FADE, {
          mode: GameMode.FREE_SPINS,
          reelPositions: [0, 0, 0, 0, 0],
          reelSetId: reelSets[GameMode.FREE_SPINS],
          callback: () => {
            this.handleDestroy();
          },
        });
      },
      preventDefaultDestroy: true,
    });
  }

  public handleDestroy(): void {
    eventManager.off(EventTypes.PICKEM_SHOT_RESOLVED);
    this.destroy({ children: true, texture: false, baseTexture: false });
  }

  private resize(width: number, height: number): void {
    //
  }
}

export default PickemContainer;
