import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import AudioHowl from '@phoenix7dev/play-music';
import { Button, Popup } from '@phoenix7dev/shared-components/';

import { ISongs } from '../../config';
import { ModalOpeningTypes } from '../../global.d';
import { configGql, setIsModalOpeningInProgress, setIsOpenAutoplayModal } from '../../gql';
import { IConfig } from '../../gql/d';

import AutoPlaySettings from '.';

const AutoPlaySettingsMenu: React.FC = () => {
  const [showPopup, setShowPopup] = useState(false);
  const { data: configData } = useQuery<IConfig>(configGql);
  const { t } = useTranslation();
  const { isOpenAutoplayModal } = configData!;

  useEffect(() => {
    if (isOpenAutoplayModal) {
      AudioHowl.stop({ type: ISongs.SFX_UI_Close });
      AudioHowl.play({ type: ISongs.SFX_UI_MenuOpen });
    }
    setShowPopup(isOpenAutoplayModal);
  }, [isOpenAutoplayModal]);

  useEffect(() => {
    if (showPopup && configData?.isSpinInProgress) {
      setShowPopup(false);
      setIsOpenAutoplayModal(false);
    }
  }, [configData?.isSpinInProgress, showPopup]);

  const handleClose = (directClickToCloseIcon = false) => {
    if (
      setIsModalOpeningInProgress() &&
      setIsModalOpeningInProgress() !== ModalOpeningTypes.AUTOPLAY &&
      setIsModalOpeningInProgress() !== ModalOpeningTypes.BUY_FEATURE_POPUP &&
      !directClickToCloseIcon
    )
      return;

    setTimeout(() => {
      if (!AudioHowl.isPlaying(ISongs.SFX_UI_MenuOpen) && !AudioHowl.isPlaying(ISongs.SFX_UI_SpinStart)) {
        AudioHowl.play({ type: ISongs.SFX_UI_Close });
      }
    }, 0);
    setIsOpenAutoplayModal(false);
  };

  return (
    <Popup id="popup" className="popup" showNow={showPopup} setShowNow={() => handleClose()}>
      <div className="popup__title">{t('autoPlay')}</div>
      <Button intent="close" className="popup__close" onClick={() => handleClose(true)} />
      <AutoPlaySettings />
    </Popup>
  );
};

export default AutoPlaySettingsMenu;
