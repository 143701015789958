import { ResourceTypes } from '../../resources.d';

export const pickemConfig: { [key in number]: ResourceTypes } = {
  0: ResourceTypes.bottle1,
  1: ResourceTypes.bottle4,
  2: ResourceTypes.bottle2,
  3: ResourceTypes.bottle3,
  4: ResourceTypes.bottle1,
  5: ResourceTypes.bottle1,
  6: ResourceTypes.bottle2,
  7: ResourceTypes.bottle3,
  8: ResourceTypes.bottle1,
  9: ResourceTypes.bottle4,
  10: ResourceTypes.bottle3,
  11: ResourceTypes.bottle2,
  12: ResourceTypes.bottle1,
  13: ResourceTypes.bottle2,
  14: ResourceTypes.bottle4,
};
