import i18n from 'i18next';
import * as _ from 'lodash';
import MultiStyleText from 'pixi-multistyle-text';
import * as PIXI from 'pixi.js';

import { EventTypes, MessageBannerProps } from '../../global.d';
import Animation from '../animations/animation';
import AnimationChain from '../animations/animationChain';
import { TweenProperties } from '../animations/d';
import SpineAnimation from '../animations/spine';
import Tween from '../animations/tween';
import Backdrop from '../backdrop/backdrop';
import { TextField } from '../components/TextField';
import ViewContainer from '../components/container';
import { eventManager } from '../config';

import { BANNER_HEIGHT, BANNER_POSITION_X, BANNER_POSITION_Y, BANNER_WIDTH } from './config';

interface IOptions {
  text?: string;
  position?: number;
  styles?: MultiStyleText['textStyles'];
}

class MessageBanner extends ViewContainer {
  private animation: SpineAnimation | null = null;

  private title: TextField;

  private subtitle: TextField;

  private btn: TextField;

  private additionalText: TextField;

  private bindedHandleDestroy = this.handleDestroy.bind(this);

  private bannerBg: string;

  private disabled: boolean;

  backdrop: Backdrop;

  private props: MessageBannerProps;

  constructor(props: MessageBannerProps) {
    super();
    this.props = props;
    this.backdrop = new Backdrop();
    this.backdrop.show();
    this.x = BANNER_POSITION_X;
    this.y = BANNER_POSITION_Y;
    this.bannerBg = props.bannerBg;
    this.visible = true;
    this.interactive = true;
    this.disabled = true;
    this.on('click', () => {
      this.startOutAnimation(`${this.bannerBg}_out`);
    });
    this.on('touchstart', () => {
      this.startOutAnimation(`${this.bannerBg}_out`);
    });
    this.title = this.initText({
      ...props.title,
    });
    this.subtitle = this.initText({
      ...props.subtitle,
    });
    this.additionalText = this.initText({
      ...props.additionalText,
    });
    this.btn = this.initText({
      ...props.btn,
    });
    this.init();
    this.startInAnimation(`${this.bannerBg}_in`, `${this.bannerBg}_loop`);
    if (props.onInitCallback) {
      props.onInitCallback();
    }
    eventManager.addListener(EventTypes.MANUAL_DESTROY_MESSAGE_BANNER, this.bindedHandleDestroy.bind(this));
    eventManager.addListener(EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER, () => {
      this.startOutAnimation(`${this.bannerBg}_out`);
    });
  }

  private init(): void {
    this.addChild(this.backdrop);
    this.initAnimation();
    this.addChild(this.title.getText());
    this.addChild(this.subtitle.getText());
    this.addChild(this.additionalText.getText());
    this.addChild(this.btn.getText());
  }

  initAnimation(): void {
    this.animation = new SpineAnimation({}, PIXI.Loader.shared.resources.popup.spineData!);
    this.animation.spine.x = 535;
    this.animation.spine.y = 380;
    this.addChild(this.animation!.spine);
  }

  private startInAnimation(inAnim: string, loopAnim: string): void {
    this.toggleItemsVisibility(true);
    this.animation!.setAnimation(inAnim, false);
    this.animation!.start();

    this.animation!.complete = [];
    this.animation!.addOnComplete(() => {
      this.disabled = false;
      this.startLoopAnimation(loopAnim, true);
    });
  }

  private startLoopAnimation(anim: string, isLoop = false): void {
    this.animation!.setAnimation(anim, isLoop);
    this.animation!.start();

    this.animation!.complete = [];
  }

  private startOutAnimation(anim: string): void {
    if (!this.disabled) {
      this.disabled = true;
      this.toggleItemsVisibility(false);
      this.animation!.setAnimation(anim, false);
      this.animation!.start();
      this.animation!.complete = [];
      this.animation!.addOnComplete(() => {
        setTimeout(() => {
          this.handleClick(this.props);
        }, 0);
      });
    }
  }

  private startFadeAnimation(item: TextField, beginValue: number, duration: number): void {
    const animationChain: AnimationChain = new AnimationChain();
    const fade = this.getFadeAnimation(item, beginValue, duration);
    animationChain.appendAnimation(fade);
    animationChain.start();
  }

  private getFadeAnimation(item: TextField, alpha: number, duration: number): Animation {
    const animation = new Tween({
      object: item.text,
      duration,
      propertyBeginValue: alpha === 0 ? 0 : 1,
      target: alpha === 0 ? 1 : 0,
      property: TweenProperties.ALPHA,
    });

    return animation;
  }

  private handleClick(props: MessageBannerProps): void {
    if (props.callback) {
      props.callback();
      props.callback = undefined;
    }
    if (!props.preventDefaultDestroy) {
      this.handleDestroy();
    }
  }

  private toggleItemsVisibility(visibility: boolean): void {
    const alpha = visibility ? 0 : 1;
    const duration = visibility ? 1500 : 200;
    this.startFadeAnimation(this.title, alpha, duration);
    this.startFadeAnimation(this.subtitle, alpha, duration);
    this.startFadeAnimation(this.additionalText, alpha, duration);
    this.startFadeAnimation(this.btn, alpha, duration);
  }

  private initText(options: IOptions): TextField {
    const text = new TextField(i18n.t(options.text || ''), BANNER_WIDTH - 300, 300, undefined, true, options.styles);
    text.text.y = options.position ? options.position : 30;
    text.text.x = BANNER_WIDTH / 2;
    text.text.visible = !!options.text;
    text.text.anchor.set(0.5, 0);
    text.text.alpha = 0;

    return text;
  }

  public handleDestroy(): void {
    eventManager.removeListener(EventTypes.MANUAL_DESTROY_MESSAGE_BANNER, this.bindedHandleDestroy);
    eventManager.removeAllListeners(EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER);

    this.destroy({ children: false, texture: false, baseTexture: false });
  }
}

export default MessageBanner;
