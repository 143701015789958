import { gql } from '@apollo/client';

import { queryParams } from '../utils';

import {
  BET,
  BET_RESULT,
  BONUS,
  CLIENT_SLOT_AUTOPLAY_CONDITIONS,
  CLIENT_SLOT_SETTINGS,
  LINE_SET,
  PAGE_INFO,
  PAYLINE,
  REEL_SET,
  REPLAY_BET,
  SLOT,
  SLOT_ICON,
  SLOT_SETTINGS,
  USER,
  USER_BALANCE,
  USER_BONUS,
} from './fragments';

export const getUserGql = gql`
  ${USER}
  ${USER_BALANCE}
  query ${queryParams.has('replayBetId') ? 'replayGetUser' : 'GetUser'} {
    user {
      ...user
      balance {
        ...userBalance
      }
    }
  }
`;

export const slotHistoryGql = gql`
  ${PAGE_INFO}
  ${BET}
  ${BET_RESULT}
  query ${queryParams.has('replayBetId') ? 'replaySlotHistory' : 'slotHistory'}($input: GetBetsInput!) {
    bets(input: $input) {
      pageInfo {
        ...pageInfo
      }
      edges {
        cursor
        node {
          ...bet
          result {
            ...betResult
          }
          userBonus {
            bonusId
          }
        }
      }
    }
  }
`;

export const userBonusBetsGql = gql`
  ${PAGE_INFO}
  query ${queryParams.has('replayBetId') ? 'replayBonusBets' : 'userBonusBets'}($input: GetBetsInput!) {
    bets(input: $input) {
      pageInfo {
        ...pageInfo
      }
    }
  }
`;

export const slotBetGql = gql`
  ${BET}
  ${BET_RESULT}
  query ${queryParams.has('replayBetId') ? 'uselessReplayBet' : 'bet'}($input: GetBetInput!) {
    bet(input: $input) {
      ...bet
      result {
        ...betResult
      }
    }
  }
`;

export const getSlotGql = gql`
  ${SLOT}
  ${REEL_SET}
  ${SLOT_ICON}
  ${SLOT_SETTINGS}
  ${CLIENT_SLOT_SETTINGS}
  ${CLIENT_SLOT_AUTOPLAY_CONDITIONS}
  ${LINE_SET}
  query getSlot($input: GetSlotInput!) {
    slot(input: $input) {
      ...slot
      reels {
        ...reelSet
      }
      icons {
        ...slotIcon
      }
      settings {
        ...slotSettings
      }
      clientSettings {
        ...clientSlotSettings
      }
      lineSets {
        ...lineSet
      }
    }
  }
`;

export const getBonuses = gql`
  ${BONUS}
  query GetBonuses($input: GetBonusesInput) {
    bonuses(input: $input) {
      ...bonus
    }
  }
`;

export const getUserBonuses = gql`
  ${USER_BONUS}
  query ${queryParams.has('replayBetId') ? 'getReplayUserBonuses' : 'getUserBonuses'}($input: GetUserBonusesInput) {
    userBonuses(input: $input) {
      ...userBonus
    }
  }
`;

export const stressfulGql = gql`
  query Stressful {
    stressful @client
  }
`;

export const configGql = gql`
  query Config {
    isAuthorized @client
    isLeftHandMode @client
    isMobile @client
    isTurboSpin @client
    isSoundOn @client
    soundValue @client
    isSpinInProgress @client
    isMiniPayTable @client
    isSlotBusy @client
    isSlotStopped @client
    isEnabledSpaceSpin @client
    isSkipIntroScreen @client
    isShowSoundToast @client
    isShowSuspendedToast @client
    isOpenMenuModal @client
    isOpenBetSettingsModal @client
    isOpenAutoplayModal @client
  }
`;

export const getProgressGql = gql`
  query GetProgress {
    progress @client
  }
`;

export const getBetAmountGql = gql`
  query GetBet {
    betAmount @client
  }
`;

export const getWinAmountGql = gql`
  query GetWin {
    winAmount @client
  }
`;

export const getIsSlotStoppedGql = gql`
  query IsSlotStopped {
    isSlotStopped @client
  }
`;

export const getAutoSpinsSettingsGql = gql`
  query GetAutoSpinsSettings {
    autoSpinsAmount @client
    stopOnWinExceeds @client
    stopOnBalanceIncrease @client
    stopOnBalanceDecrease @client
    isStopOnAnyWin @client
    isStopOnFeatureWin @client
    isStopOnWinExceeds @client
    isStopOnBalanceIncrease @client
    isStopOnBalanceDecrease @client
    autoSpinsStartBalance @client
  }
`;

export const getAutoSpinsGql = gql`
  query GetAutoSpin {
    isAutoSpins @client
    autoSpinsLeft @client
  }
`;

export const getGameModeGql = gql`
  query GetAutoSpin {
    gameMode @client
  }
`;

export const getSlotConfigGql = gql`
  query GetSlotConfig {
    slotConfig @client {
      id
      lines
    }
  }
`;

export const isStoppedGql = gql`
  query IsStopped {
    isSlotStopped @client
  }
`;

export const replayBetGql = gql`
  ${REPLAY_BET}
  ${REEL_SET}
  ${LINE_SET}
  ${BONUS}
  ${BET_RESULT}
  ${PAYLINE}
  ${USER_BALANCE}
  ${USER_BONUS}
  query replayBet($betId: ID!) {
    placeBet: replayRound(betId: $betId) {
      bet {
        ...bet
        reelSet {
          ...reelSet
          __typename
        }
        lineSet {
          ...lineSet
          __typename
        }
        userBonus: replayUserBonus {
          bonus {
            ...bonus
            __typename
          }
          __typename
        }
        result {
          ...betResult
          __typename
        }
        __typename
      }
      paylines {
        ...payline
        __typename
      }
      balance {
        placed {
          ...userBalance
          __typename
        }
        settled {
          ...userBalance
          __typename
        }
        __typename
      }
      rewards {
        ... on ReplayBonusReward {
          userBonusId
          userBonus: replayUserBonus {
            ...userBonus
            bonus {
              ...bonus
              __typename
            }
            __typename
          }
          __typename
        }
        ... on BetCoinReward {
          amount
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;

export const betsByInitialRoundId = gql`
  query betsByInitialRoundId($initialRoundId: UUID!) {
    betsByInitialRoundId(initialRoundId: $initialRoundId) {
      id
      result {
        reelPositions
        winCoinAmount
      }
      reelSetId
    }
  }
`;
