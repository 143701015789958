import * as PIXI from 'pixi.js';

const winCounterStyle = new PIXI.TextStyle({
  dropShadowAlpha: 0.8,
  dropShadowAngle: 1,
  dropShadowBlur: 10,
  dropShadowDistance: 8,
  fill: ['#ffa800', '#efad2d', '#ff7400', '#ffc109', '#ffc00e'],
  fillGradientStops: [0.4],
  fontFamily: 'NotoSans-SemiCondensedBold',
  fontSize: 100,
  fontWeight: 'bold',
  lineJoin: 'round',
  miterLimit: 65,
  strokeThickness: 12,
  whiteSpace: 'normal',
});

export const pickemTitleTextStyle = {
  fontSize: 35,
  fontFamily: 'NotoSans-SemiCondensedBold',
  fill: '0xcc9f58',
};

export const pickemTitleValueTextStyle = {
  fontSize: 35,
  fontFamily: 'NotoSans-SemiCondensedBold',
  fill: '0xcc9f58',
};

export const miniPayTableMultipliersStyle = {
  fontSize: 35,
  fontFamily: 'NotoSans-SemiCondensedBold',
  fill: '0xf6e87e',
};

export const bottomContainerTextStyle = {
  fontFamily: 'NotoSans-SemiCondensedBold',
  fill: '#f6e87e',
  dropShadow: true,
  dropShadowAngle: 0.75,
  dropShadowBlur: 3,
  dropShadowColor: '#9b511b',
  dropShadowDistance: 2,
};

export const winValueStyles = new PIXI.TextStyle(winCounterStyle);

// BuyFeature Button Text
// BuyFeature Button Text
export const buyFeatureTextStyle = new PIXI.TextStyle({
  fontFamily: 'NotoSans-SemiCondensedBold',
  fontWeight: 'bold',
  align: 'center',
  fontSize: 42,
  fill: '#f5b83c',
  wordWrap: true,
  wordWrapWidth: 120,
});
export const buyFeatureHoverTextStyle = new PIXI.TextStyle({
  fontFamily: 'NotoSans-SemiCondensedBold',
  fontWeight: 'bold',
  align: 'center',
  fontSize: 42,
  fill: '#ffe13c',
  wordWrap: true,
  wordWrapWidth: 120,
});
export const buyFeatureClickTextStyle = new PIXI.TextStyle({
  fontFamily: 'NotoSans-SemiCondensedBold',
  fontWeight: 'bold',
  align: 'center',
  fontSize: 42,
  fill: '#271710',
  wordWrap: true,
  wordWrapWidth: 120,
});
export const buyFeatureDisableTextStyle = new PIXI.TextStyle({
  fontFamily: 'NotoSans-SemiCondensedBold',
  fontWeight: 'bold',
  align: 'center',
  fontSize: 42,
  fill: '#c4beb6',
  wordWrap: true,
  wordWrapWidth: 120,
});

export const sliderValueTextStyle = { fontSize: 14, fill: 0xeaeaea };
