export enum ResourceTypes {
  backdrop = 'backdrop',
  bottle1 = 'bottle1',
  bottle2 = 'bottle2',
  bottle3 = 'bottle3',
  bottle4 = 'bottle4',
  buttonCancel = 'buttonCancel',
  buttonHistory = 'buttonHistory',
  buttonInfo = 'buttonInfo',
  buttonLobby = 'buttonLobby',
  buttonOk = 'buttonOk',
  buttonSetting = 'buttonSetting',
  buyFeatureBtnHover = 'buyFeatureBtnHover',
  buyFeatureBtnNotActive = 'buyFeatureBtnNotActive',
  buyFeatureBtnPressed = 'buyFeatureBtnPressed',
  buyFeatureBtn = 'buyFeatureBtn',
  buyFeatureCancelBtnHover = 'buyFeatureCancelBtnHover',
  buyFeatureCancelBtnPressed = 'buyFeatureCancelBtnPressed',
  buyFeatureCancelBtn = 'buyFeatureCancelBtn',
  buyFeatureInput = 'buyFeatureInput',
  buyFeatureMinusBtnDisabled = 'buyFeatureMinusBtnDisabled',
  buyFeatureMinusBtnHover = 'buyFeatureMinusBtnHover',
  buyFeatureMinusBtnPressed = 'buyFeatureMinusBtnPressed',
  buyFeatureMinusBtn = 'buyFeatureMinusBtn',
  buyFeatureOkBtnDisabled = 'buyFeatureOkBtnDisabled',
  buyFeatureOkBtnHover = 'buyFeatureOkBtnHover',
  buyFeatureOkBtnPressed = 'buyFeatureOkBtnPressed',
  buyFeatureOkBtn = 'buyFeatureOkBtn',
  buyFeaturePlusBtnDisabled = 'buyFeaturePlusBtnDisabled',
  buyFeaturePlusBtnHover = 'buyFeaturePlusBtnHover',
  buyFeaturePlusBtnPressed = 'buyFeaturePlusBtnPressed',
  buyFeaturePlusBtn = 'buyFeaturePlusBtn',
  buyFeaturePopup = 'buyFeaturePopup',
  frame = 'frame',
  infoBtnAutoplayStop = 'infoBtnAutoplayStop',
  infoBtnAutoplay = 'infoBtnAutoplay',
  infoBtnBetSettings = 'infoBtnBetSettings',
  infoBtnInfo = 'infoBtnInfo',
  infoBtnMenu = 'infoBtnMenu',
  infoBtnSound = 'infoBtnSound',
  infoBtnSpin = 'infoBtnSpin',
  infoBtnStop = 'infoBtnStop',
  infoBtnTurbo = 'infoBtnTurbo',
  infoPaylines = 'infoPaylines',
  introBg = 'introBg',
  introReel1 = 'introReel1',
  introReel2 = 'introReel2',
  introReel3 = 'introReel3',
  logo = 'logo',
  patron = 'patron',
  pickemBg = 'pickemBg',
  pickemFrame = 'pickemFrame',
  slotBg = 'slotBg',
  slotFreeSpinBg = 'slotFreeSpinBg',
  blurLow10 = 'blurLow10',
  blurLowA = 'blurLowA',
  blurLowJ = 'blurLowJ',
  blurLowK = 'blurLowK',
  blurLowQ = 'blurLowQ',
  blurMiddle1 = 'blurMiddle1',
  blurMiddle2 = 'blurMiddle2',
  blurScatter = 'blurScatter',
  blurTop1 = 'blurTop1',
  blurTop2 = 'blurTop2',
  blurTop3 = 'blurTop3',
  blurTop4 = 'blurTop4',
  blurWild = 'blurWild',
  low10 = 'low10',
  lowA = 'lowA',
  lowJ = 'lowJ',
  lowK = 'lowK',
  lowQ = 'lowQ',
  middle1 = 'middle1',
  middle2 = 'middle2',
  scatter = 'scatter',
  top1 = 'top1',
  top2 = 'top2',
  top3 = 'top3',
  top4 = 'top4',
  wild = 'wild',
}
