import { useQuery } from '@apollo/client';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { formatNumber } from '@phoenix7dev/utils-fe';

import { config } from '../../config';
import { IUserBalance } from '../../global.d';
import { getBetAmountGql, getUserGql, setCoinValue } from '../../gql';
import { ResourceTypes } from '../../resources.d';
import { countCoins, showCurrency } from '../../utils';
import Resources from '../../utils/resources';

import { IPaytableData } from './d';
import styles from './info.module.scss';

const calcMultiplier = (multiplier: number, betAmount: number): number =>
  countCoins({ totalAmount: betAmount / 20, coinValue: setCoinValue() }) * multiplier;

const hasTranslate = (slug: ResourceTypes): boolean => {
  return slug === ResourceTypes.wild || slug === ResourceTypes.scatter;
};

const PaytableComponent: React.FC = () => {
  const { t } = useTranslation();
  const { data: userData } = useQuery<{ user: IUserBalance }>(getUserGql);
  const { data: dataBet } = useQuery<{
    betAmount: number;
  }>(getBetAmountGql);

  const {
    user: { balance },
  } = userData!;

  const { betAmount } = dataBet!;

  const data: IPaytableData[] = config.payTableData;

  const { currency } = balance;

  return (
    <div className={styles['paytable-list']}>
      {data.map(({ combos, slug }) => (
        <div key={slug} className={styles['paytable-list__item']}>
          <div className={styles.img}>
            <img draggable="false" alt={slug} src={Resources.getSource(slug)} />
            {hasTranslate(slug) && <div className={styles['paytable-list__title']}>{t(`infoPayTable_${slug}`)}</div>}
          </div>
          <div className={styles.content}>
            {combos.map((i) => (
              <div key={i.pattern}>
                <span className={styles.multiplier}>{i.pattern} </span>
                {formatNumber({
                  currency,
                  value: calcMultiplier(i.multiplier, betAmount),
                  showCurrency: showCurrency(currency),
                })}
              </div>
            ))}
            {slug === 'wild' && <div className={styles.description}>{t('infoPayTable_wild_description')}</div>}
            {slug === 'scatter' && <div className={styles.description}>{t('infoPayTable_scatter_description')}</div>}
          </div>
        </div>
      ))}
    </div>
  );
};

export default PaytableComponent;
