import { useQuery, useReactiveVar } from '@apollo/client';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import AudioHowl from '@phoenix7dev/play-music';
import { Button, Grid, GridItem, RangeSlider, Toggle } from '@phoenix7dev/shared-components';
import { formatNumber } from '@phoenix7dev/utils-fe';

import { ISongs, config } from '../../config';
import { IUserBalance, ModalOpeningTypes } from '../../global.d';
import {
  getAutoSpinsSettingsGql,
  getBetAmountGql,
  getUserGql,
  setAutoSpinsAmount,
  setAutoSpinsLeft,
  setAutoSpinsStartBalance,
  setCoinAmount,
  setCoinValue,
  setIsAutoSpins,
  setIsModalOpeningInProgress,
  setIsStopOnAnyWin,
  setIsStopOnBalanceDecrease,
  setIsStopOnBalanceIncrease,
  setIsStopOnFeatureWin,
  setIsStopOnWinExceeds,
  setLastRegularWinAmount,
  setSlotConfig,
  setStopOnBalanceDecrease,
  setStopOnBalanceIncrease,
  setStopOnBalanceIncreaseSliderValue,
  setStopOnWinExceeds,
  setStopOnWinExceedsSliderValue,
} from '../../gql';
import { ISlotConfig } from '../../gql/d';
import { ResourceTypes } from '../../resources.d';
import { normalizeCoins, showCurrency } from '../../utils';
import Resources from '../../utils/resources';

import { AutoPlaySettingsInput } from './AutoPlaySettingsInput';
import styles from './autoPlaySettings.module.scss';
import { IAutoPlaySettingsProps } from './d';

const AutoPlaySettings: React.FC<IAutoPlaySettingsProps> = (props) => {
  const { t } = useTranslation();
  const { className = '', handleClose = () => {} } = props;
  const {
    clientSettings: { autoplay },
  } = useReactiveVar<ISlotConfig>(setSlotConfig);
  const { data } = useQuery<{
    autoSpinsAmount: number;
    isStopOnAnyWin: boolean;
    isStopOnFeatureWin: boolean;
    isStopOnWinExceeds: boolean;
    isStopOnBalanceIncrease: boolean;
    isStopOnBalanceDecrease: boolean;
    stopOnWinExceeds: number;
    stopOnBalanceIncrease: number;
    stopOnBalanceDecrease: number;
  }>(getAutoSpinsSettingsGql);
  useEffect(() => {
    setAutoSpinsAmount(config.autoplay.autoSpinValues[3]);
  }, []);

  const { data: userData } = useQuery<{ user: IUserBalance }>(getUserGql);
  const { data: dataBet } = useQuery<{
    betAmount: number;
  }>(getBetAmountGql);
  // TODO to be removed
  const currency = userData?.user.balance.currency || 'FUN';
  const balanceAmount = userData?.user.balance.amount || 0;
  const { betAmount } = dataBet!;
  const {
    autoSpinsAmount,
    isStopOnAnyWin,
    isStopOnFeatureWin,
    stopOnWinExceeds,
    stopOnBalanceIncrease,
    stopOnBalanceDecrease,
    isStopOnWinExceeds,
    isStopOnBalanceIncrease,
    isStopOnBalanceDecrease,
  } = data!;

  const winExceedsSliderValue = useReactiveVar<number>(setStopOnWinExceedsSliderValue);
  const balanceIncreaseSliderValue = useReactiveVar<number>(setStopOnBalanceIncreaseSliderValue);
  const onAutoSpinsAmountChange = useCallback((value: number) => {
    setAutoSpinsAmount(value);
  }, []);

  const handleStartAutoplay = useCallback(() => {
    if (setIsModalOpeningInProgress() !== ModalOpeningTypes.BUY_FEATURE_POPUP) {
      AudioHowl.play({ type: ISongs.SFX_UI_General });
      setLastRegularWinAmount(0);
      setAutoSpinsLeft(autoSpinsAmount);
      setIsAutoSpins(true);
      setAutoSpinsStartBalance(balanceAmount);
      handleClose();
    }
  }, [balanceAmount, autoSpinsAmount, handleClose]);

  useEffect(() => {
    if (
      autoplay.conditions.stopIfSingleWinExceeds.multipliers[winExceedsSliderValue - 1] * betAmount !==
      stopOnWinExceeds
    ) {
      setStopOnWinExceeds(betAmount);
      setStopOnWinExceedsSliderValue(1);
    }
    if (
      autoplay.conditions.stopIfBalanceIncreasesBy.multipliers[balanceIncreaseSliderValue - 1] * betAmount !==
      stopOnBalanceIncrease
    ) {
      setStopOnBalanceIncrease(betAmount);
      setStopOnBalanceIncreaseSliderValue(1);
      setStopOnBalanceDecrease(betAmount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={`${styles['autoplay-settings']} ${className}`}>
      <div className={styles.label}>{t('autoSpin')}</div>
      <div className="btn-group">
        {(autoplay.options as number[]).map((item) => (
          <Button
            intent="autoPlaySettingBtn"
            key={item}
            isActive={autoSpinsAmount === item}
            title={`${item}`}
            onClick={() => {
              AudioHowl.play({ type: ISongs.SFX_UI_BetChange });
              onAutoSpinsAmountChange(item);
            }}
          />
        ))}
      </div>
      {autoplay.conditions.stopOnAnyWin.enabled && (
        <div className={styles['autoplay-settings__item']}>
          <Grid>
            <GridItem width="3" alignX="center">
              <Toggle
                className="toggle"
                checked={isStopOnAnyWin}
                id="stop_on_won"
                onChange={() => {
                  setIsStopOnAnyWin(!isStopOnAnyWin);
                  AudioHowl.play({ type: ISongs.SFX_UI_General });
                }}
              />
            </GridItem>
            <GridItem>
              <div className={styles.label}>{t('autoPlayStop')}</div>
            </GridItem>
          </Grid>
        </div>
      )}
      {autoplay.conditions.stopIfFeatureIsWon.enabled && (
        <div className={styles['autoplay-settings__item']}>
          <Grid>
            <GridItem width="3" alignX="center">
              <Toggle
                className="toggle"
                checked={isStopOnFeatureWin}
                id="stop_on_feature_win"
                onChange={() => {
                  setIsStopOnFeatureWin(!isStopOnFeatureWin);
                  AudioHowl.play({ type: ISongs.SFX_UI_General });
                }}
              />
            </GridItem>
            <GridItem>
              <div className={styles.label}>{t('autoPlayStopOnFeatureWin')}</div>
            </GridItem>
          </Grid>
        </div>
      )}
      {autoplay.conditions.stopIfSingleWinExceeds.enabled && (
        <>
          <div className={styles['autoplay-settings__item']}>
            <Grid>
              <GridItem width="3" alignX="center">
                <Toggle
                  className="toggle"
                  checked={isStopOnWinExceeds}
                  id="stop_on_win_exceeds"
                  onChange={() => {
                    setIsStopOnWinExceeds(!isStopOnWinExceeds);
                    AudioHowl.play({ type: ISongs.SFX_UI_General });
                  }}
                />
              </GridItem>
              <GridItem>
                <div className={styles.label}>{t('autoPlayStopSingleWin')}</div>
              </GridItem>
            </Grid>
          </div>
          <div className={styles['autoplay-settings__item']}>
            <Grid>
              <GridItem width="4">
                <AutoPlaySettingsInput
                  disabled={!isStopOnWinExceeds}
                  value={formatNumber({
                    currency,
                    value: normalizeCoins(stopOnWinExceeds),
                    showCurrency: showCurrency(currency),
                  })}
                />
              </GridItem>
              <GridItem>
                <RangeSlider
                  disabled={!isStopOnWinExceeds}
                  className="range-slider"
                  min={1}
                  max={autoplay.conditions.stopIfSingleWinExceeds.multipliers.length}
                  value={winExceedsSliderValue}
                  onChange={(v) => {
                    setStopOnWinExceedsSliderValue(v);
                    setStopOnWinExceeds(
                      setCoinAmount() *
                        autoplay.conditions.stopIfSingleWinExceeds.multipliers[v - 1] *
                        setSlotConfig().lines.length,
                    );
                    AudioHowl.play({ type: ISongs.SFX_UI_BetChange });
                  }}
                />
              </GridItem>
            </Grid>
          </div>
        </>
      )}
      {autoplay.conditions.stopIfBalanceIncreasesBy.enabled && (
        <>
          <div className={styles['autoplay-settings__item']}>
            <Grid>
              <GridItem width="3" alignX="center">
                <Toggle
                  className="toggle"
                  checked={isStopOnBalanceIncrease}
                  id="stop_on_balance_increase"
                  onChange={() => {
                    setIsStopOnBalanceIncrease(!isStopOnBalanceIncrease);
                    AudioHowl.play({ type: ISongs.SFX_UI_General });
                  }}
                />
              </GridItem>
              <GridItem>
                <div className={styles.label}>{t('autoPlayStopBalanceUp')}</div>
              </GridItem>
            </Grid>
          </div>
          <div className={styles['autoplay-settings__item']}>
            <Grid>
              <GridItem width="4">
                <AutoPlaySettingsInput
                  disabled={!isStopOnBalanceIncrease}
                  value={`${formatNumber({
                    currency,
                    value: normalizeCoins(stopOnBalanceIncrease),
                    showCurrency: showCurrency(currency),
                  })}`}
                />
              </GridItem>
              <GridItem>
                <RangeSlider
                  disabled={!isStopOnBalanceIncrease}
                  className="range-slider"
                  min={1}
                  max={autoplay.conditions.stopIfBalanceIncreasesBy.multipliers.length}
                  value={balanceIncreaseSliderValue}
                  onChange={(v) => {
                    setStopOnBalanceIncreaseSliderValue(v);
                    setStopOnBalanceIncrease(
                      setCoinAmount() *
                        autoplay.conditions.stopIfBalanceIncreasesBy.multipliers[v - 1] *
                        setSlotConfig().lines.length,
                    );
                    AudioHowl.play({ type: ISongs.SFX_UI_BetChange });
                  }}
                />
              </GridItem>
            </Grid>
          </div>
        </>
      )}
      {autoplay.conditions.stopIfBalanceDecreasesBy.enabled && (
        <>
          <div className={styles['autoplay-settings__item']}>
            <Grid>
              <GridItem width="3" alignX="center">
                <Toggle
                  className="toggle"
                  checked={isStopOnBalanceDecrease}
                  id="stop_on_balance_decrease"
                  onChange={() => {
                    setIsStopOnBalanceDecrease(!isStopOnBalanceDecrease);
                    AudioHowl.play({ type: ISongs.SFX_UI_General });
                  }}
                />
              </GridItem>
              <GridItem>
                <div className={styles.label}>{t('autoPlayStopBalanceDown')}</div>
              </GridItem>
            </Grid>
          </div>
          <div className={styles['autoplay-settings__item']}>
            <Grid>
              <GridItem width="4">
                <AutoPlaySettingsInput
                  disabled={!isStopOnBalanceDecrease}
                  value={`${formatNumber({
                    currency,
                    value: normalizeCoins(stopOnBalanceDecrease),
                    showCurrency: showCurrency(currency),
                  })}`}
                />
              </GridItem>
              <GridItem>
                <RangeSlider
                  disabled={!isStopOnBalanceDecrease}
                  className="range-slider"
                  min={betAmount}
                  max={balanceAmount / setCoinValue()}
                  value={stopOnBalanceDecrease}
                  onChange={(v) => {
                    setStopOnBalanceDecrease(v);
                    AudioHowl.play({ type: ISongs.SFX_UI_BetChange });
                  }}
                />
              </GridItem>
            </Grid>
          </div>
        </>
      )}
      <div className="btn-group">
        <button className={styles.playBtn} disabled={autoSpinsAmount === 0} onClick={handleStartAutoplay}>
          <img
            draggable="false"
            className={styles.bigger}
            src={Resources.getSource(ResourceTypes.buttonOk)}
            alt="infoBtnBetAmountOk"
          />
        </button>
      </div>
    </div>
  );
};

export default AutoPlaySettings;
