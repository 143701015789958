/* eslint-disable @typescript-eslint/no-explicit-any */
import _ from 'lodash';
import * as PIXI from 'pixi.js';

import { EventTypes, GameMode } from '../../global.d';
import { setBrokenGame, setGameMode, setUserLastBetResult } from '../../gql/cache';
import { isFreeSpinMode } from '../../utils';
import {
  eventManager,
  REELS_AMOUNT,
  SLOTS_CONTAINER_HEIGHT,
  SLOTS_CONTAINER_WIDTH,
  SLOTS_PER_REEL_AMOUNT,
} from '../config';
import StickyWild from './stickyWild';

class StickyWildsContainer extends PIXI.Container {
  constructor() {
    super();

    this.visible = setBrokenGame() && setGameMode() === GameMode.FREE_SPINS;
    this.width = SLOTS_CONTAINER_WIDTH;
    this.height = SLOTS_CONTAINER_HEIGHT;
    //   this.sortableChildren = true;
    eventManager.addListener(
      EventTypes.CHANGE_MODE,
      this.setContainerVisibility.bind(this),
    );
    eventManager.addListener(
      EventTypes.SET_SLOTS_VISIBILITY,
      this.setStickyWildsVisibility.bind(this),
    );

    this.initStickyWildsGrid();

    if (setBrokenGame() && setGameMode() === GameMode.FREE_SPINS) {
      const stickyWildPostitons = setUserLastBetResult()?.data?.features
        ?.multipliers
        ? setUserLastBetResult()?.data?.features?.multipliers
        : {};

      this.setSpinResult(stickyWildPostitons);
    }
  }

  private initStickyWildsGrid() {
    for (let row = 0; row < SLOTS_PER_REEL_AMOUNT; row++) {
      for (let col = 0; col < REELS_AMOUNT; col++) {
        const stickyWild = new StickyWild(row, col);
        this.addChild(stickyWild);
      }
    }
  }

  public setSpinResult(stickyWildPositions: { [key: number]: number }): void {
    if (this.visible) {
      Object.entries(stickyWildPositions)
        .map(([position, multiplier]) => [Number(position), multiplier])
        .forEach(([position, multiplier]) => {
          if (!this.children[position].visible) {
            this.children[position].visible = true;
            (this.children[position] as any).isSticky = true;
            (this.children[position] as any).onStick(multiplier);
          }
        });
    }
  }

  private setContainerVisibility(settings: { mode: GameMode }) {
    if (isFreeSpinMode(settings.mode)) {
      this.visible = true;
    } else {
      this.resetContainer();
    }
  }

  private setStickyWildsVisibility(slots: number[], visibility: boolean): void {
    if (this.visible) {
      slots.forEach((index) => {
        if ((this.children[index] as any).isSticky) {
          this.children[index].visible = visibility;
        }
      });
    }
  }

  private resetContainer() {
    this.visible = false;
    this.children.forEach((stickyWild) => {
      stickyWild.visible = false;
      (stickyWild as any).isSticky = false;
    });
  }
}

export default StickyWildsContainer;
