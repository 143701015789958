import * as PIXI from 'pixi.js';

const font = 'NotoSans-SemiCondensedBold';
const GRADIENT_GOLD_TEXT_COLOR_CONFIG = {
  fill: ['#FBC41C', '#F5FF3C', '#FFFFFF', '#FF8737', '#F9FF11', '#FBC41C', '#f08418', '#9f6518', '#ffeb19'],
  fillGradientStops: [0.1, 0.31, 0.42, 0.43, 0.58, 0.65, 0.72, 0.75, 0.8],
};

export const buyFeatureTitleStyle = {
  fontSize: 70,
  fontFamily: font,
  fill: '#000000',
};

export const buyFeatureConfirmTitleStyle = new PIXI.TextStyle({
  fontSize: 43,
  fontFamily: font,
  fill: '#000000',
  align: 'center',
});

export const totalCostTextStyle = {
  fontSize: 40,
  fontFamily: font,
  fill: '#712605',
};

export const totalCostTextAmountStyle = {
  fontSize: 65,
  fontFamily: font,
  fill: '#000000',
};

export const betTextStyle = {
  fontSize: 40,
  fontFamily: font,
  fill: '#000000',
};

export const betValueStyle = {
  fontSize: 36,
  fontFamily: font,
  fill: '#efc67e',
};
