import { useQuery, useReactiveVar } from '@apollo/client';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { formatNumber } from '@phoenix7dev/utils-fe';

import { IUserBalance } from '../../global.d';
import { getUserGql, setSlotConfig } from '../../gql';
import { ISlotConfig } from '../../gql/d';
import { getBetsSetting } from '../../utils/fromFragment';

import styles from './info.module.scss';

const GameRules: React.FC = () => {
  const i18n = useTranslation();
  const { t } = i18n;
  const { data: userData } = useQuery<{ user: IUserBalance }>(getUserGql);
  const { isBuyFeatureEnabled } = useReactiveVar<ISlotConfig>(setSlotConfig);

  const {
    user: { balance },
  } = userData!;
  const betSettings = getBetsSetting();
  const { currency } = balance;
  const minBet = formatNumber({ currency, value: betSettings.minBetCount });
  const maxBet = formatNumber({ currency, value: betSettings.maxBetCount });
  const version = window.__ENV__?.APP_VERSION ?? 'develop';

  const gameRules = [
    { key: 'infoGameRules1_1', text: i18n.t('infoGameRules1_1'), buyFeatureOnly: false },
    { key: 'infoGameRules1_2', text: i18n.t('infoGameRules1_2'), buyFeatureOnly: false },
    { key: 'infoGameRules1_3', text: i18n.t('infoGameRules1_3'), buyFeatureOnly: false },
    { key: 'infoGameRules1_4', text: i18n.t('infoGameRules1_4'), buyFeatureOnly: false },
    { key: 'infoGameRules1_5', text: i18n.t('infoGameRules1_5'), buyFeatureOnly: false },
    { key: 'infoGameRules1_6', text: i18n.t('infoGameRules1_6'), buyFeatureOnly: false },
    { key: 'infoGameRules1_7', text: i18n.t('infoGameRules1_7'), buyFeatureOnly: false },
    { key: 'infoGameRules1_8', text: i18n.t('infoGameRules1_8'), buyFeatureOnly: false },
    { key: 'infoGameRules1_9', text: i18n.t('infoGameRules1_9'), buyFeatureOnly: false },
  ];

  return (
    <div className={styles.gameRules}>
      <h1 className={styles.title}>{t('infoGameRulesTitle')}</h1>
      <section className={`${styles.p} ${styles.left}`}>
        {gameRules
          .filter((v) => isBuyFeatureEnabled || !v.buyFeatureOnly)
          .map((v) => (
            <div key={v.key} dangerouslySetInnerHTML={{ __html: v.text }} />
          ))}
      </section>
      <section className={`${styles.p} ${styles.left}`}>
        <div
          dangerouslySetInnerHTML={{
            __html: t('infoGameRules2', {
              minBet,
            }),
          }}
        />
        <div
          dangerouslySetInnerHTML={{
            __html: t('infoGameRules3', {
              maxBet,
            }),
          }}
        />
      </section>
      <section className={`${styles.p} ${styles.left}`}>
        <div
          dangerouslySetInnerHTML={{
            __html: t('infoGameRules4', {
              rtp: '96.55%',
            }),
          }}
        />
        {isBuyFeatureEnabled ? (
          <div
            dangerouslySetInnerHTML={{
              __html: t('infoGameRules5', {
                rtp: '96.66%',
              }),
            }}
          />
        ) : (
          ''
        )}
        <div
          className={`${styles.p} ${styles.left}`}
          dangerouslySetInnerHTML={{
            __html: t('infoGameRulesVersion', {
              version,
            }),
          }}
        />
      </section>
    </div>
  );
};

export default GameRules;
