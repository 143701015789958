import { t } from 'i18next';
import _ from 'lodash';
import * as PIXI from 'pixi.js';

import AnimationGroup from '../animations/animationGroup';
import { TweenProperties } from '../animations/d';
import SpineAnimation from '../animations/spine';
import Tween from '../animations/tween';
import { TextField } from '../components/TextField';
import { PICKEM_AWARD_HEIGHT, PICKEM_AWARD_WIDTH } from '../config';

import { pickemConfig } from './pickemConfig';

class Pickem extends PIXI.Container {
  private animation: SpineAnimation | null = null;

  public rect: PIXI.Graphics;

  public awardSymbol: PIXI.Sprite;

  public awardTextValue: PIXI.Text;

  public awardText: TextField;

  constructor(id: number) {
    super();
    this.x = 0;
    this.y = 0;
    this.rect = this.initRect();
    this.addChild(this.rect);
    this.awardSymbol = this.initAwardSymbol(id);
    this.addChild(this.awardSymbol);
    this.awardTextValue = this.initAwardTextValue();
    this.addChild(this.awardTextValue);
    this.awardText = this.initAwardText();
    this.addChild(this.awardText.getText());
    this.initAnimation();
  }

  initRect(): PIXI.Graphics {
    const rect = new PIXI.Graphics();
    rect.beginFill(0x000000);
    rect.alpha = 0.75;
    rect.drawRect(0, 0, PICKEM_AWARD_WIDTH, PICKEM_AWARD_HEIGHT);
    rect.alpha = 0;
    rect.zIndex = 2;

    return rect;
  }

  initAwardSymbol(id: number): PIXI.Sprite {
    const symbol = new PIXI.Sprite(PIXI.Texture.from(pickemConfig[id]));
    symbol.x = this.width / 2;
    symbol.y = this.height / 2;
    symbol.zIndex = 3;
    symbol.anchor.set(0.5);

    return symbol;
  }

  initAwardTextValue(): PIXI.Text {
    const award = new PIXI.Text('', {
      fontFamily: 'NotoSans-SemiCondensedBold',
      fontSize: 75,
      fill: '0xcc9f58',
      align: 'center',
      lineHeight: 70,
      leading: 0,
    });
    award.alpha = 0;
    award.x = this.width / 2;
    award.y = this.height / 2 + 17;
    award.zIndex = 3;
    award.anchor.set(0.5, 1);

    return award;
  }

  initAwardText(): TextField {
    const award = new TextField(`${t('pickemBottleText')}`, 190, 80, {
      fontFamily: 'NotoSans-SemiCondensedBold',
      fontSize: 35,
      fill: '0xcc9f58',
      align: 'center',
      lineHeight: 30,
      leading: 0,
    });
    award.text.alpha = 0;
    award.text.x = this.width / 2;
    award.text.y = this.height / 2;
    award.text.zIndex = 3;
    award.text.anchor.set(0.5, 0);

    return award;
  }

  initAnimation(): void {
    this.animation = new SpineAnimation({}, PIXI.Loader.shared.resources.pickem_bonus.spineData!);
    this.animation.spine.x = this.width / 2;
    this.animation.spine.y = this.height / 2;
    this.animation.addOnStart(() => {
      this.addChild(this.animation!.spine);
    });
  }

  private startAnimation(anim: string, isStopped: boolean, count: number): void {
    this.animation!.setAnimation(anim, false);
    this.animation!.start();

    this.animation!.complete = [];
    this.animation!.addOnComplete(() => {
      if (!isStopped) {
        this.awardSymbol.visible = false;
        this.awardTextValue.text = `+${count}`;

        const animation = new AnimationGroup({});
        const textWidth = this.awardText.getText().width;
        const textHeight = this.awardText.getText().height;
        const scaleFactor = Math.min(Math.min(80 / textHeight, 190 / textWidth), 1);

        const animationScaleValueX = new Tween({
          object: this.awardTextValue.scale,
          duration: 200,
          propertyBeginValue: 0,
          target: 1,
          property: TweenProperties.X,
        });
        const animationScaleValueY = new Tween({
          object: this.awardTextValue.scale,
          duration: 200,
          propertyBeginValue: 0,
          target: 1,
          property: TweenProperties.Y,
        });
        const animationValue = new Tween({
          object: this.awardTextValue,
          duration: 200,
          propertyBeginValue: 0,
          target: 1,
          property: TweenProperties.ALPHA,
        });

        const animationScaleTextX = new Tween({
          object: this.awardText.text.scale,
          duration: 200,
          propertyBeginValue: 0,
          target: scaleFactor,
          property: TweenProperties.X,
        });
        const animationScaleTextY = new Tween({
          object: this.awardText.text.scale,
          duration: 200,
          propertyBeginValue: 0,
          target: scaleFactor,
          property: TweenProperties.Y,
        });

        const animationText = new Tween({
          object: this.awardText.text,
          duration: 200,
          propertyBeginValue: 0,
          target: 1,
          property: TweenProperties.ALPHA,
        });

        animation.addAnimation(animationScaleValueX);
        animation.addAnimation(animationScaleValueY);
        animation.addAnimation(animationValue);
        animation.addAnimation(animationScaleTextX);
        animation.addAnimation(animationScaleTextY);
        animation.addAnimation(animationText);
        animation.start();
      }
    });
  }

  public handlePickem(count: number): number {
    this.startAnimation('bottle_explosine', false, count);
    if (this.awardSymbol.visible) {
      this.awardSymbol.visible = false;
      return count;
    }
    return 0;
  }
}

export default Pickem;
