import * as PIXI from 'pixi.js';

const font = 'NotoSans-SemiCondensedBold';

export const textStyle = new PIXI.TextStyle({
  dropShadowAlpha: 0.8,
  dropShadowAngle: 1,
  dropShadowBlur: 10,
  dropShadowDistance: 8,
  fill: '#ffa800',
  fillGradientStops: [0.4],
  fontFamily: font,
  fontSize: 70,
  fontWeight: 'bold',
  lineJoin: 'round',
  miterLimit: 65,
  strokeThickness: 12,
  whiteSpace: 'normal',
});

export const spinsStyle = new PIXI.TextStyle({
  dropShadowAlpha: 0.8,
  dropShadowAngle: 1,
  dropShadowBlur: 10,
  dropShadowDistance: 8,
  fill: ['#e41000', '#f10', '#ff7521'],
  fillGradientStops: [0.4],
  fontFamily: font,
  fontSize: 70,
  fontWeight: 'bold',
  lineJoin: 'round',
  miterLimit: 65,
  strokeThickness: 12,
  whiteSpace: 'normal',
});
