import { useReactiveVar } from '@apollo/client';
import * as PIXI from 'pixi.js';
import React, { CSSProperties, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { setSlotConfig } from '../../gql';
import { ISlotConfig } from '../../gql/d';
import { ResourceTypes } from '../../resources.d';
import Resources from '../../utils/resources';

import styles from './info.module.scss';

export interface MyCustomCSS extends CSSProperties {
  'container-type': string | number;
}
const FreeSpinFeature: React.VFC = () => {
  const i18n = useTranslation();
  const { t } = i18n;

  const infoFeatureFreeSpinDescs = [
    { key: 'infoFeatureFreeSpinDesc_1', text: i18n.t('infoFeatureFreeSpinDesc_1') },
    { key: 'infoFeatureFreeSpinDesc_2', text: i18n.t('infoFeatureFreeSpinDesc_2') },
    { key: 'infoFeatureFreeSpinDesc_3', text: i18n.t('infoFeatureFreeSpinDesc_3') },
    { key: 'infoFeatureFreeSpinDesc_4', text: i18n.t('infoFeatureFreeSpinDesc_4') },
    { key: 'infoFeatureFreeSpinDesc_5', text: i18n.t('infoFeatureFreeSpinDesc_5') },
  ];

  return (
    <section className={styles.feature}>
      <h1 className={styles.title}>{t('infoFeatureFreeSpinTitle')}</h1>
      <div className={`${styles.p} ${styles.left}`}>
        {infoFeatureFreeSpinDescs.map((o) => (
          <div key={o.key} dangerouslySetInnerHTML={{ __html: o.text }} />
        ))}
      </div>
    </section>
  );
};

const PickeEmBonus: React.VFC = () => {
  const i18n = useTranslation();
  const { t } = i18n;

  const infoFeaturePickEmBonusDescs = [
    { key: 'infoFeaturePickEmBonusDesc_1', text: i18n.t('infoFeaturePickEmBonusDesc_1') },
    { key: 'infoFeaturePickEmBonusDesc_2', text: i18n.t('infoFeaturePickEmBonusDesc_2') },
    { key: 'infoFeaturePickEmBonusDesc_3', text: i18n.t('infoFeaturePickEmBonusDesc_3') },
    { key: 'infoFeaturePickEmBonusDesc_4', text: i18n.t('infoFeaturePickEmBonusDesc_4') },
    { key: 'infoFeaturePickEmBonusDesc_5', text: i18n.t('infoFeaturePickEmBonusDesc_5') },
  ];

  return (
    <section className={styles.feature}>
      <h1 className={styles.title}>{t('infoFeaturePickEmBonusTitle')}</h1>
      <div className={`${styles.p} ${styles.left}`}>
        {infoFeaturePickEmBonusDescs.map((o) => (
          <div key={o.key} dangerouslySetInnerHTML={{ __html: o.text }} />
        ))}
      </div>
    </section>
  );
};

const MultiplierWilds: React.VFC = () => {
  const i18n = useTranslation();
  const { t } = i18n;

  const infoFeatureMultiplierWildsDescs = [
    { key: 'infoFeatureMultiplierWildsDesc_1', text: i18n.t('infoFeatureMultiplierWildsDesc_1') },
    { key: 'infoFeatureMultiplierWildsDesc_2', text: i18n.t('infoFeatureMultiplierWildsDesc_2') },
    { key: 'infoFeatureMultiplierWildsDesc_3', text: i18n.t('infoFeatureMultiplierWildsDesc_3') },
    { key: 'infoFeatureMultiplierWildsDesc_4', text: i18n.t('infoFeatureMultiplierWildsDesc_4') },
    { key: 'infoFeatureMultiplierWildsDesc_5', text: i18n.t('infoFeatureMultiplierWildsDesc_5') },
  ];

  return (
    <section className={styles.feature}>
      <h1 className={styles.title}>{t('infoFeatureMultiplierWildsTitle')}</h1>
      <div className={`${styles.p} ${styles.left}`}>
        {infoFeatureMultiplierWildsDescs.map((o) => (
          <div key={o.key} dangerouslySetInnerHTML={{ __html: o.text }} />
        ))}
      </div>
    </section>
  );
};

const BuyAFeature: React.VFC = () => {
  const i18n = useTranslation();
  const { t } = i18n;

  const ref = useRef<HTMLInputElement>(null);

  const calcFontSize = (fontSize: string | number, boxWidth: number, textWidth: number) => {
    if (boxWidth >= textWidth) {
      return Number(fontSize);
    }

    return Math.floor(Number(fontSize) * (boxWidth / textWidth));
  };

  const infoFeatureBuyDescs = [
    { key: 'infoFeatureBuyDesc_1', text: i18n.t('infoFeatureBuyDesc_1') },
    { key: 'infoFeatureBuyDesc_2', text: i18n.t('infoFeatureBuyDesc_2') },
    { key: 'infoFeatureBuyDesc_3', text: i18n.t('infoFeatureBuyDesc_3') },
    { key: 'infoFeatureBuyDesc_4', text: i18n.t('infoFeatureBuyDesc_4') },
  ];

  useEffect(() => {
    if (ref.current) {
      const style = new PIXI.TextStyle({
        fontSize: 28,
        fontWeight: '700',
      });
      const text1Width = PIXI.TextMetrics.measureText(t('infoFeatureBuyText1'), style).width;
      const text2Width = PIXI.TextMetrics.measureText(t('infoFeatureBuyText2'), style).width;
      const boxWidth = ref.current.clientWidth - 15;

      if (text1Width > text2Width) {
        const fontSize = calcFontSize(style.fontSize, boxWidth, text1Width);

        ref.current.style.fontSize = `${fontSize}px`;
      } else {
        const fontSize = calcFontSize(style.fontSize, boxWidth, text2Width);

        ref.current.style.fontSize = `${fontSize}px`;
      }
    }
  }, [t]);

  return (
    <section className={styles.feature}>
      <h1 className={styles.title}>{t('infoFeatureBuyTitle')}</h1>
      <div
        className={styles.figcontainer}
        style={{ backgroundImage: `url('${Resources.getSource(ResourceTypes.buyFeatureBtn)}')` } as MyCustomCSS}
      >
        <div className={styles.fig__img} ref={ref}>
          <img draggable="false" src={Resources.getSource(ResourceTypes.buyFeatureBtn)} alt="buy-a-feature" />
          <div className={styles.fig__text}>
            <p className={`${styles.featuretext}`} dangerouslySetInnerHTML={{ __html: t('infoFeatureBuyText') }} />
          </div>
        </div>
      </div>
      <div className={`${styles.p} ${styles.left}`}>
        {infoFeatureBuyDescs.map((o) => (
          <div
            key={o.key}
            dangerouslySetInnerHTML={{
              __html: t(o.text, {
                multiplier: '134',
              }),
            }}
          />
        ))}
      </div>
    </section>
  );
};

const Features: React.VFC = () => {
  const { isBuyFeatureEnabled } = useReactiveVar<ISlotConfig>(setSlotConfig);

  return (
    <div className={styles.gameRules}>
      <MultiplierWilds />
      <PickeEmBonus />
      <FreeSpinFeature />
      {isBuyFeatureEnabled ? <BuyAFeature /> : ''}
    </div>
  );
};

export default Features;
