import { useMutation } from '@apollo/client';
import * as _ from 'lodash';
import React, { useEffect } from 'react';

import { EventTypes, FeatureTypes, IBonus } from '../../global.d';
import { buyBonusGql, isStoppedGql, setBonuses, setCoinAmount, setCoinValue, setCurrentBonusId } from '../../gql';
import { eventManager } from '../../slotMachine/config';

const BuyFeature: React.FC = () => {
  const [buyBonus, { client }] = useMutation(buyBonusGql, {
    onCompleted(data: { buyBonus: { id: string } }) {
      client.writeQuery({
        query: isStoppedGql,
        data: {
          isSlotStopped: false,
        },
      });
      setCurrentBonusId(data.buyBonus.id);
      eventManager.emit(EventTypes.START_BUY_FEATURE_ROUND); // Triggers free spins
    },
  });

  useEffect(() => {
    eventManager.on(EventTypes.HANDLE_BUY_BONUS, () => {
      const bonuses = setBonuses();
      const bonus = _.chain(bonuses)
        .filter((bonus) => bonus.type === FeatureTypes.SPECIAL_ROUND)
        .get(0, {})
        .value() as IBonus;
      buyBonus({
        variables: {
          input: {
            id: bonus.id,
            coinValue: setCoinValue(),
            coinAmount: setCoinAmount(),
          },
        },
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default BuyFeature;
